import { ENVIRONMENT, COOKIES } from '../constants';
import getEnvVariable from './environment';
import { getCookie } from './cookieHelpers';
import { getBrowserFingerprintHeader } from './browserFingerprint';

/**
 * Create a new request object for use with fetch API
 * @param {string} path - the path of the resource
 * @param {object} [opts] - additional request options
 * @param {string} [opts.method=POST] - request method
 * @param {string} [opts.body] - request body
 * @return {Request} request object
 */
const createRequest = (path, opts = {}) => {
  const method = opts.method || 'POST';
  const body = opts.body ? JSON.stringify(opts.body) : undefined;
  const token = getCookie(COOKIES.MAKO_AUTH);
  const headers = new Headers();
  headers.append('Authorization', `token ${token}`);
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  
  const base64BrowserFingerprint = getBrowserFingerprintHeader();
  if (base64BrowserFingerprint) {
    headers.append('Browser-Fingerprint', getBrowserFingerprintHeader());
  }

  const req = new Request(`${getEnvVariable(ENVIRONMENT.MAKO_URL)}${path}`, { method, body, headers });
  return req;
};

export default createRequest;
