import React from 'react';
import withProfileInputs from '../containers/withProfileInputs';
const Styles = require('./profileForm.module.scss');

interface HoneypotProps {
  setTitleHoneypot: (e: React.FormEvent<HTMLInputElement>) => void;
  setIndustryHoneypot: (e: React.FormEvent<HTMLSelectElement>) => void;
  profileInputs: {
    titleHoneypot: string;
    industryHoneypot: string;
  };
}

export function Honeypot({
  setTitleHoneypot,
  setIndustryHoneypot,
  profileInputs
}: HoneypotProps): React.ReactElement {
  return (
    <section>
      <div className={Styles['titleId']}>
        <label htmlFor="title">Title</label>
        <input
          id="title"
          name="title"
          type="text"
          value={profileInputs.titleHoneypot}
          onChange={setTitleHoneypot}
          tabIndex={-1}
          autoComplete="false"
          data-role="title-id"
        />
      </div>
      <div className={Styles['industryId']}>
        <label htmlFor="industry">Industry</label>
        <select
          id="industry"
          name="industry"
          value={profileInputs.industryHoneypot}
          onChange={setIndustryHoneypot}
          tabIndex={-1}
          data-role="industry-id"
        >
          <option value="" selected></option>
          <option value="technology">Technology</option>
          <option value="ecommerce">eCommerce</option>
          <option value="retail">Retail</option>
          <option value="healthcare">Healthcare</option>
          <option value="other">Other</option>
        </select>
      </div>
    </section>
  );
}

export default withProfileInputs(Honeypot);
