import {
  ACTIONS, ADDONS, ENVIRONMENT, LOCALSTORAGE,
} from '../constants';
import getEnvVariable from '../utils/environment';

const initialState = {
  [ADDONS.DEDICATED_IP]: 0,
  ipPrice: 0,
  [ADDONS.EASE]: 3,
  easePrice: 0,
};
const getInitialState = () => {
  const cachedState = localStorage.getItem(LOCALSTORAGE.REDUX_STATE);
  if (cachedState && cachedState.addOns) {
    return cachedState.addOns;
  }
  return initialState;
};

const addOns = (state = getInitialState(), action) => {
  switch (action.type) {
    case ACTIONS.SET_PACKAGE: {
      const packages = action.activePackage;
      const adsPackageId = getEnvVariable(ENVIRONMENT.ADS_PACKAGE_ID);
      const shouldRemoveAddons = packages && packages.some(pkg => pkg.is_aws || pkg.id === adsPackageId);
      return shouldRemoveAddons ? initialState : state;
    }
    case ACTIONS.ADD_ADDON: {
      const newState = {
        ...state,
        ...action.addons,
      };
      return newState;
    }
    case ACTIONS.REMOVE_ADDON: {
      const newState = {
        ...state,
        [action.addon.type]: 0,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default addOns;
