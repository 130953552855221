import React, { useState } from 'react';
import { analytics } from '@sendgrid/bloodhound';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import { Icon } from '@sendgrid/ui-components/icon';
import Styles from './mobileproduct.module.scss';
import { MultiProductCard } from './MultiProductCard';
import { TotalCard } from './TotalCard';
import { AddOnCard } from './AddOnCard';
import { calculateTotalPrice, dollarize } from '../../utils/displayHelpers';
import { YourProductsEvents } from '../../analytics/yourProductsEvents';

export const MobileProduct = ({ activePackage, addOns }) => {
  const [isAccordionOpen, toggleAccordion] = useState(false);

  const totalPrice = calculateTotalPrice(activePackage, addOns);

  if (totalPrice === 0) {
    return null;
  }

  const toggleFullSummary = () => {
    analytics.track(YourProductsEvents.productSummaryToggled());
    toggleAccordion(!isAccordionOpen);
  };

  return (
    <section className={Styles.mobileProduct}>

      <div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        className={Styles.productToggle}
        onClick={() => toggleFullSummary()}
        onKeyPress={() => toggleFullSummary()}
      >
        <div className={Styles.productToggleContainer}>
          <div className={Styles.toggleTitle}>
            <p>
              {isAccordionOpen ? 'Hide ' : 'Show '}
              full summary
              {isAccordionOpen ? <Icon className={Styles.invertedCaret} type="caret" /> : <Icon type="caret" />}
            </p>
          </div>
          <div className={Styles.toggleTotal}>
            <p>
              <strong>{dollarize(totalPrice)}</strong>
              /month*
            </p>
            <span>*Taxes may apply</span>
          </div>
        </div>
      </div>
      <AnimateHeight duration={500} height={isAccordionOpen ? 'auto' : 0}>
        <div className={Styles.productSummary}>
          <div>
            {activePackage.map(product => (
              <MultiProductCard
                key={product.id}
                product={product}
              />
            ))}
            {(addOns.ip > 0 || addOns.ease > 3)
              && (
                <AddOnCard
                  addOns={addOns}
                />
              )
            }
            <TotalCard
              activePackage={activePackage}
              addOns={addOns}
            />
          </div>
        </div>
      </AnimateHeight>
    </section>
  );
};


MobileProduct.propTypes = {
  addOns: PropTypes.shape({
    ease: PropTypes.number,
    ip: PropTypes.number,
  }).isRequired,
  activePackage: PropTypes.arrayOf(PropTypes.shape({
    adjusted_price: PropTypes.number,
    base_price: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default MobileProduct;
