import {
  Page, Section, SubSection, ElementType, EventType,
} from './constants';

const pathToPageMap = {
  '/': Page.accountInputs,
  '/billing': Page.billing,
};

const getPageFromPath = () => {
  const pathName = window.location.pathname;
  return pathToPageMap[pathName] || pathName;
};

export const YourProductsEvents = {
  overageRatesClicked: () => ({
    event: 'overage rates clicked',
    properties: {
      page: getPageFromPath(),
      section: Section.yourProducts,
      subSection: SubSection.totalPrice,
      elementType: ElementType.link,
      eventType: EventType.click,
    },
  }),
  productSummaryToggled: () => ({
    event: 'product summary toggled',
    properties: {
      page: getPageFromPath(),
      section: Section.yourProducts,
      subSection: SubSection.totalPrice,
      elementType: ElementType.link,
      eventType: EventType.click,
    },
  }),
  pricingLinkClicked: () => ({
    event: 'pricing link clicked',
    properties: {
      page: getPageFromPath(),
      section: Section.yourProducts,
      subSection: SubSection.noProducts,
      elementType: ElementType.link,
      eventType: EventType.click,
    },
  }),
};
