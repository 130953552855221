import { analytics } from '@sendgrid/bloodhound';
import {
  ACTIONS, COOKIES, ERRORTYPES, TELEMETRY_ERROR_VALUES,
} from '../constants';
import { setCookie, removeCookie } from '../utils/cookieHelpers';
import { telemetry } from '../utils/telemetry';
import { AccountInputsEvents } from '../analytics/accountInputsEvents';
import createRequest from '../utils/requestService';

// eslint-disable-next-line
export const postRecaptcha = recaptchaResponse => (dispatch) => {
  if (recaptchaResponse === undefined) {
    return {
      type: ACTIONS.POST_RECAPTCHA,
      captcha_isValid: false,
      captcha_errorType: ERRORTYPES.CAPTCHA_INVALID,
    };
  }
  return fetch(createRequest(
    '/v3/public/s2s/signup/recaptcha_verify',
    {
      body: { recaptcha: recaptchaResponse },
    }
  )).then((response) => {
    if (response.ok) {
      analytics.track(AccountInputsEvents.recaptchaCompleted(true));
      response.json().then((json) => {
        setCookie(COOKIES.SIGNUP_SESSION, json.signup_session_token);
        dispatch({
          type: ACTIONS.POST_RECAPTCHA,
          captcha_isValid: true,
          captcha_errorType: ERRORTYPES.NO_TYPE_SET,
          signup_session_token: json.signup_session_token,
        });
      });
    } else {
      const {
        TAGS, LEVELS, TAG_VALUES, ERROR_MESSAGES,
      } = TELEMETRY_ERROR_VALUES;
      removeCookie(COOKIES.SIGNUP_SESSION);
      dispatch({
        type: ACTIONS.POST_RECAPTCHA,
        captcha_isValid: false,
        captcha_errorType: ERRORTYPES.NO_TYPE_SET,
        signup_session_token: '',
      });
      analytics.track(AccountInputsEvents.recaptchaCompleted(false));
      response.json()
        .then((json) => {
          telemetry.captureError(
            json.error,
            TAGS.SIGNUP_TOKEN,
            TAG_VALUES.SIGNUP_TOKEN_FAILURE,
            LEVELS.FATAL,
          );
        })
        .catch(() => {
          telemetry.captureError(
            ERROR_MESSAGES.NO_MESSAGE,
            TAGS.SIGNUP_TOKEN,
            TAG_VALUES.SIGNUP_TOKEN_FAILURE,
            LEVELS.FATAL,
          );
        });
    }
  }).catch(() => {
    dispatch({
      type: ACTIONS.POST_RECAPTCHA,
      captcha_isValid: false,
      captcha_errorType: ERRORTYPES.CAPTCHA_INVALID,
      signup_session_token: '',
    });
  });
};
