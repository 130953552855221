import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Alert } from '@sendgrid/ui-components/alert';
import withAppIndicators from '../../containers/withAppIndicators';
import Header from '../Header';
import FullPageLoader from '../FullPageLoader';
import SideBar from '../SideBar';
import Footer from '../Footer';
import '../../styles/signup.global.scss';
import accountTypes from '../../constants/accountTypes';
import withPackages from '../../containers/withPackages';
import withAddons from '../../containers/withAddOns';
import { MultiSideBar } from '../MultiProduct/MultiSideBar';
import { MobileProduct } from '../MultiProduct/MobileProduct';
import Styles from './layout.module.scss';
import { WriteSelectors } from './qa-hooks';

export const Layout = (props) => {
  const { activePackage = [], packagesLoaded } = props.packages;
  const { addOns = {} } = props;
  const { location } = props;
  const shouldShowProducts = packagesLoaded && (location.pathname === '/billing' || location.pathname === '/');

  return (
    <Fragment>
      {props.appIndicators.alertActive
        && (
          <Alert
            type={props.appIndicators.alertType}
            onClick={() => props.dismissAlert()}
            className={Styles['signup-alert']}
            showIcon
            {...WriteSelectors.signupAlert}
          >
            {props.appIndicators.alertText}
          </Alert>
        )
      }
      {props.appIndicators.isPageLoading && <FullPageLoader />}
      <main className="signup-base-wrapper">
        <article {...WriteSelectors.signupContainer} className="signup-container" data-role="signup-container">
          <section className="signup" data-role="signup-content">
            <Header
              title={props.title}
              subtitle={props.subtitle}
              activePackage={activePackage}
            />
            {shouldShowProducts && (
              <MobileProduct
                activePackage={activePackage}
                addOns={addOns}
              />
            )}
            {props.children}
            {props.location.pathname === '/billing'
              && (
                <Footer
                  activePackage={activePackage}
                  addOns={addOns}
                />
              )
            }
          </section>
        </article>
        <Switch>
          <Route exact path="/account_details" component={null} />
          <Route exact path="/unified_login/signup" component={null} />
          <Route exact path="/unified_login/account_details" component={null} />
          <Route exact path="/ads/account_details" component={null} />
          <Route exact path="/ads" render={renderProps => <SideBar {...renderProps} sideBarType={accountTypes.ads} />} />
          <Route exact path="/ads/billing" render={renderProps => <SideBar {...renderProps} sideBarType={accountTypes.ads} />} />
          <Route exact path="/declined" component={null} />
          <Route render={() => (packagesLoaded
              && (
                <MultiSideBar
                  activePackage={activePackage}
                  addOns={addOns}
                />
              )
          )}
          />
        </Switch>
      </main>
    </Fragment>
  );
};

Layout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  dismissAlert: PropTypes.func.isRequired,
  appIndicators: PropTypes.shape({
    isPageLoading: PropTypes.bool.isRequired,
    alertActive: PropTypes.bool.isRequired,
    alertType: PropTypes.string.isRequired,
    alertText: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  packages: PropTypes.shape({
    activePackage: PropTypes.array,
    packagesLoaded: PropTypes.bool,
  }).isRequired,
  addOns: PropTypes.shape({
    ease: PropTypes.number,
    ip: PropTypes.number,
  }),
};

Layout.defaultProps = {
  subtitle: '',
  addOns: {},
};

export default withRouter(withAppIndicators(withPackages(withAddons(Layout))));
