import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Styles from './footer.module.scss';
import { calculateEstimatedProratedPrice, calculateTotalPrice, dollarize } from '../utils/displayHelpers';

const Footer = ({ activePackage, addOns }) => {
  const totalPrice = calculateTotalPrice(activePackage, addOns);
  const proratedPrice = calculateEstimatedProratedPrice(totalPrice);
  return (
    <footer data-role="footer-container">
      <center>
        <p className={`small ${Styles.taxesfooter}`}>
          By confirming, your account will be created and
          {' '}
          <strong>
            {' '}
            you will be charged a prorated price of
            {' '}
            {dollarize(proratedPrice)}
          </strong>
          {' '}
          for your new Twilio SendGrid products.
        </p>
      </center>
    </footer>
  );
};

Footer.propTypes = {
  addOns: PropTypes.shape({
    ease: PropTypes.number,
    ip: PropTypes.number,
  }).isRequired,
  activePackage: PropTypes.arrayOf(PropTypes.shape({
    adjusted_price: PropTypes.number,
    base_price: PropTypes.number,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};


export default withRouter(Footer);
