import { ACTIONS, ERRORTYPES } from '../constants';
import { validateEmail } from '../utils/formInput';

const INITIAL_STATE = {
  email: '',
  emailError: null,
  isValidForm: false,
  emailSent: false,
};

const emailConfirmationInputs = (state = INITIAL_STATE, action) => {
  let isValidForm = validateEmail(state.email).errorType === ERRORTYPES.NO_TYPE_SET;
  switch (action.type) {
    case ACTIONS.SET_CONFIRMATION_EMAIL: {
      const emailErrorType = validateEmail(action.email).errorType;
      const emailError = emailErrorType === ERRORTYPES.NO_TYPE_SET ? null : emailErrorType;
      isValidForm = validateEmail(action.email).errorType === ERRORTYPES.NO_TYPE_SET;
      return {
        ...state,
        email: action.email,
        emailError,
        isValidForm,
      };
    }
    case ACTIONS.ON_SEND: {
      return {
        ...state,
        emailSent: action.emailSent,
        isValidForm,
      };
    }
    default:
      return state;
  }
};

export default emailConfirmationInputs;
