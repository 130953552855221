import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const Auth0Callback = () => {
  const router = useHistory();
  const {handleRedirectCallback} = useAuth0();
  useEffect(() => {
    const handleCallback = async () => {
      const { appState: { targetUrl } } = await handleRedirectCallback();
      router.replace(targetUrl)
    }
    handleCallback();
  }, [])
  return null;
}
