import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { render } from 'react-dom';
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import reducer from './reducers';
import { telemetry } from './utils/telemetry';
import LoadAnalytics from './utils/Analytics';
import getEnvVariable from './utils/environment';
import { LOCALSTORAGE, ENVIRONMENT } from './constants';

import Billing from './pages/Billing';
import Profile from './pages/Profile';
// import AdsProfile from './pages/AdsProfile';
import Declined from './pages/Declined';
// import Ads from './pages/Ads';
import RouteChangeDetector from './components/RouteChangeDetector';
import './index.scss';
import { initBrowserFingerprint } from './utils/browserFingerprint';
import telemetryErrorValues from './constants/telemetryErrorValues';
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0Callback} from "./pages/Auth0Callback"
import {UnifiedLoginSignup} from "./pages/UnifiedLoginSignup"
import {UnifiedLoginProfile} from "./pages/UnifiedLoginProfile"
import { NavbarContainer } from "./components/Navbar/NavbarContainer";
import { Theme } from "@twilio-paste/theme";

// initialize Sentry
telemetry.load();

// eslint-disable-next-line
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// only use dev tools if available and env is 'development'
const composeEnhancers =
  (process.env.NODE_ENV === 'development' && reduxDevTools) || compose;

initBrowserFingerprint().catch((error) => {
  const {
    TAGS,
    LEVELS,
    TAG_VALUES,
  } = telemetryErrorValues;
  telemetry.captureError(
    error.message,
    TAGS.BROWSER_FINGERPRINT,
    TAG_VALUES.BROWSER_FINGERPRINT_ERROR,
    LEVELS.ERROR,
  );
});

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

store.subscribe(() => {
  const { addOns, packages } = store.getState();
  localStorage.setItem(
    LOCALSTORAGE.REDUX_STATE,
    JSON.stringify({
      addOns,
      packages
    })
  );
});

const redirectToAdsMarketingPage = () => {
  window.location.href = 'https://sendgrid.com/solutions/ads/';
};

const redirectToUnifiedSignupPage = () => {
  const loginHostURL = getEnvVariable(ENVIRONMENT.LOGIN_HOST_URL)
  window.location.href = `${loginHostURL}/unified_login/start?screen_hint=signup`;
};

LoadAnalytics(getEnvVariable(ENVIRONMENT.SEGMENT_KEY));

const reCaptchaKey = getEnvVariable(ENVIRONMENT.RECAPTCHA_SITE_KEY_V3);
const auth0Audience = getEnvVariable(ENVIRONMENT.AUTH0_AUDIENCE);
const auth0ClientId = getEnvVariable(ENVIRONMENT.AUTH0_CLIENT_ID);
const auth0Domain = getEnvVariable(ENVIRONMENT.AUTH0_DOMAIN);

render(
  <Theme.Provider theme="twilio" >
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0/callback`,
        audience: auth0Audience
      }}
    >
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        <Provider store={store}>
          <NavbarContainer />
          <Router>
            <RouteChangeDetector>
              <Switch>
                <Route path="/auth0/callback"  component={Auth0Callback} />
                <Route path="/account_details" component={Profile} />
                <Route path="/unified_login/signup" component={UnifiedLoginSignup} />
                <Route path="/unified_login/account_details" component={UnifiedLoginProfile} />
                <Route
                  path="/ads/account_details"
                  render={redirectToAdsMarketingPage}
                />
                <Route path="/billing" component={Billing} />
                <Route path="/ads/billing" render={redirectToAdsMarketingPage} />
                <Route path="/declined" component={Declined} />
                <Route path="/ads" render={redirectToAdsMarketingPage} />
                <Route path="/" render={redirectToUnifiedSignupPage} />
                <Redirect to="/" />
              </Switch>
            </RouteChangeDetector>
          </Router>
        </Provider>
      </GoogleReCaptchaProvider>
    </Auth0Provider>
  </Theme.Provider>,
  document.getElementById('root')
);
