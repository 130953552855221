import React from 'react';
import { Topbar, TopbarActions } from '@twilio-paste/core/topbar';
import { Box } from '@twilio-paste/core/box';
import {
  UserDialogListItem,
  UserDialogList,
  UserDialogUserEmail,
  UserDialogUserName,
  UserDialogUserInfo,
  UserDialogContainer,
  UserDialog,
  useUserDialogListState
} from '@twilio-paste/core/user-dialog';
import {
  ProductSwitcher,
  ProductSwitcherButton,
  ProductSwitcherItem,
  useProductSwitcherState
} from '@twilio-paste/core/product-switcher';
import { UserIcon } from '@twilio-paste/icons/cjs/UserIcon';
import { LogoTwilioIcon } from '@twilio-paste/icons/cjs/LogoTwilioIcon';
import { ProductSegmentIcon } from '@twilio-paste/icons/cjs/ProductSegmentIcon';
import { ProductEmailAPIIcon } from '@twilio-paste/icons/cjs/ProductEmailAPIIcon';
import { LogOutIcon } from '@twilio-paste/icons/cjs/LogOutIcon';
import { Button } from '@twilio-paste/core/button';
import getEnvVariable from '../../utils/environment';
import { ENVIRONMENT } from '../../constants';
const SendgridLogo = 'https://uiux-assets.sendgrid.com/logo/sg-twilio-lockup.svg';

const LOGIN_HOST_URL = getEnvVariable(ENVIRONMENT.LOGIN_HOST_URL);
const TWILIO_HOST = getEnvVariable(ENVIRONMENT.TWILIO_HOST);
const SEGMENT_HOST = getEnvVariable(ENVIRONMENT.SEGMENT_HOST);

// This allows us to center the logo by offsetting the width of the action items on the left side of the navbar
const NavbarActionsWidth = '92px';

export const Navbar = ({ name, email }: { name: string; email: string }) => {
  const userDialogList = useUserDialogListState();
  const productSwitcher = useProductSwitcherState();
  const encodedEmail = encodeURIComponent(email);
  const twilioConsoleUrl = `https://${TWILIO_HOST}/unified_login/start?email=${encodedEmail}`;
  const userSettingsUrl = `${twilioConsoleUrl}&deeplink=user-settings`;
  const segmentUrl = `https://${SEGMENT_HOST}/unified_login/start?email=${encodedEmail}`;

  return (
    <Topbar id="topbar">
      <Box
        display={'flex'}
        width={'100%'}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <div style={{ display: 'flex', marginLeft: NavbarActionsWidth }}>
          <img
            width={'155px'}
            height={'35px'}
            src={SendgridLogo}
            alt="SendGrid Logo"
          />
        </div>
      </Box>
      <TopbarActions justify="end">
        <ProductSwitcherButton
          {...productSwitcher}
          i18nButtonLabel="Switch products"
        />
        <ProductSwitcher {...productSwitcher} aria-label="Available products">
          <ProductSwitcherItem
            {...productSwitcher}
            name="product"
            value="twilio"
            checked={false}
            onChange={() => {
              window.location.href = twilioConsoleUrl;
            }}
            productName="Twilio Comms"
            productStrapline="SMS, Voice & Video"
            productIcon={<LogoTwilioIcon decorative />}
          />
          <ProductSwitcherItem
            {...productSwitcher}
            name="product"
            value="segment"
            checked={false}
            onChange={() => {
              window.location.href = segmentUrl;
            }}
            productName="Twilio Segment"
            productStrapline="Customer data platform"
            productIcon={<ProductSegmentIcon decorative />}
          />
          <ProductSwitcherItem
            {...productSwitcher}
            name="product"
            value="sendgrid"
            checked={true}
            productName="Twilio SendGrid"
            productStrapline="Email delivery & API"
            productIcon={<ProductEmailAPIIcon decorative />}
          />
        </ProductSwitcher>
        <UserDialogContainer
          name="User Name"
          icon={UserIcon}
          baseId="i-am-user-dialog"
        >
          <UserDialog aria-label="user menu" data-testid="basic-user-dialog">
            <UserDialogUserInfo tabIndex={-1}>
              <span style={{ wordBreak: 'break-all' }}>
                <UserDialogUserName>{name}</UserDialogUserName>
              </span>
              <span style={{ wordBreak: 'break-all' }}>
                <UserDialogUserEmail>{email}</UserDialogUserEmail>
              </span>
              <Button
                onClick={() => {
                  window.open(userSettingsUrl, '_blank');
                }}
                variant="link"
                tabIndex={-1}
              >
                <span style={{ fontWeight: 400 }}>User Settings</span>
              </Button>
            </UserDialogUserInfo>
            <UserDialogList {...userDialogList} aria-label="User menu actions">
              <UserDialogListItem
                {...userDialogList}
                tabIndex={-1}
                href={`${LOGIN_HOST_URL}/logout`}
              >
                <LogOutIcon decorative />
                Log out
              </UserDialogListItem>
            </UserDialogList>
          </UserDialog>
        </UserDialogContainer>
      </TopbarActions>
    </Topbar>
  );
};
