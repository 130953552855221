import { analytics } from '@sendgrid/bloodhound';
import { ACTIONS, COOKIES, APPINDICATORS } from '../constants';
import ERRORTYPES from '../constants/errorTypes';
import createFreeUser from '../utils/userCreation';
import createRequest from '../utils/requestService';
import { pushGTM, getPackageEventData, trackOnce } from '../utils/Analytics';
import {
  validateEmail,
  validateUsername,
  validatePassword,
  validateConfirmPassword
} from '../utils/formInput';
import { setCookie } from '../utils/cookieHelpers';
import { isPackageAds, isPaymentRequired } from '../utils/displayHelpers';
import { AccountInputsEvents } from '../analytics/accountInputsEvents';

const trackUsernameComplete = trackOnce();
const trackPasswordComplete = trackOnce();
const trackConfirmPasswordComplete = trackOnce();
const trackEmailComplete = trackOnce();
const trackAccountCreated = trackOnce();
const trackTosComplete = trackOnce();

const serviceDownError = (error, dispatch) => {
  // eslint-disable-next-line
  console.log('error: ', error);
  dispatch({ type: ACTIONS.CREATE_USER_ERROR });
  dispatch({
    type: APPINDICATORS.DISPLAY_ALERT,
    alertType: APPINDICATORS.ALERT_TYPES.DANGER,
    alertText: APPINDICATORS.ALERT_TEXT.USER_CREATION_ERROR
  });
};

export const setUsername = (username) => ({
  type: ACTIONS.SET_USERNAME,
  username
});

export const setPassword = (password) => ({
  type: ACTIONS.SET_PASSWORD,
  password
});

export const setRecaptchaToken = (recaptcha_token) => ({
  type: ACTIONS.SET_RECAPTCHA_TOKEN,
  recaptcha_token
});

// This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
export const toggleTos = () => {
  trackTosComplete(AccountInputsEvents.termsOfServiceAccepted());
  return { type: ACTIONS.TOGGLE_TOS };
};

export const blurPassword = (password) => (dispatch, getState) => {
  const responsePassword = validatePassword(
    password,
    getState().formInputs.confirmPassword_value,
    getState().formInputs.username_value
  );
  if (responsePassword.isValid) {
    trackPasswordComplete(AccountInputsEvents.passwordCompleted());
  }
};

export const setConfirmPassword = (confirmPassword) => ({
  type: ACTIONS.SET_CONFIRM_PASSWORD,
  confirmPassword
});

export const blurConfirmPassword =
  (confirmPassword) => (dispatch, getState) => {
    const responsePassword = validateConfirmPassword(
      confirmPassword,
      getState().formInputs.password_value
    );
    if (responsePassword.isValid) {
      trackConfirmPasswordComplete(
        AccountInputsEvents.confirmPasswordCompleted()
      );
    }
  };

export const setEmail = (email) => ({ type: ACTIONS.SET_EMAIL, email });

export const blurEmail = (email) => () => {
  const responseEmail = validateEmail(email);
  if (responseEmail.isValid) {
    trackEmailComplete(AccountInputsEvents.emailAddressCompleted());
  }
};

// This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
export const usernameExistsCheck =
  (username, canBeEmail = false) =>
  (dispatch) => {
    if (!validateUsername(username, canBeEmail).isValid) {
      return dispatch({
        type: ACTIONS.USERNAME_EXISTS_CHECK,
        username_unique_value: '',
        username_unique_isValid: false,
        username_unique_errorType: `${ERRORTYPES.USERNAME_PREFIX}${ERRORTYPES.USERNAME_DEFAULT}`
      });
    }
    return fetch(
      createRequest(`/v3/public/signup/username/${username}`, { method: 'GET' })
    )
      .then((response) => {
        switch (response.status) {
          case 404:
            trackUsernameComplete(AccountInputsEvents.usernameCompleted());
            return {
              isValid: true,
              errorType: ERRORTYPES.USERNAME_AVAILABLE,
              username
            };

          case 429:
            return {
              isValid: false,
              errorType: ERRORTYPES.USERNAME_TOO_MANY_REQUESTS,
              username
            };

          case 204:
            return {
              isValid: false,
              errorType: ERRORTYPES.USERNAME_TAKEN,
              username
            };
          case 400:
            return {
              isValid: false,
              errorType: ERRORTYPES.USERNAME_INVALID,
              username
            };

          default:
            return {
              isValid: false,
              errorType: ERRORTYPES.USERNAME_DEFAULT,
              username
            };
        }
      })
      .then((response) => {
        dispatch({
          type: ACTIONS.USERNAME_EXISTS_CHECK,
          username_unique_value: username,
          username_unique_isValid: response.isValid,
          username_unique_errorType: response.errorType
        });
        return response;
      })
      .catch((error) => {
        serviceDownError(error, dispatch);
      });
  };

// This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
const handleAnalytics = (response, userValues, activePackage, activeIsFree) => {
  trackAccountCreated(AccountInputsEvents.accountCreated());
  const planTraits = getPackageEventData(
    activePackage,
    'eiPlanName',
    'mcPlanName'
  );
  const queryParams = new URLSearchParams(window.location.search);
  const affiliate = queryParams.get('affiliate');
  analytics.identify({
    traits: {
      package: response.combo_package_uuid,
      affiliate,
      ...planTraits
    }
  });

  if (window.ga) {
    window.ga(
      'send',
      'event',
      'signup_cge',
      'user_created',
      activeIsFree ? 'free' : 'paid'
    );
  }

  // google tag manager
  pushGTM(response.user_id, userValues.email, activePackage);
};

// This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
export const createUser =
  (
    userValues,
    activePackage,
    activeIsFree,
    history,
    canBeEmail = false,
    onPasswordError
  ) =>
  async (dispatch) => {
    dispatch({ type: APPINDICATORS.PAGE_LOADING });
    const usernameReq = await usernameExistsCheck(
      userValues.username,
      canBeEmail
    )(dispatch);
    if (usernameReq && usernameReq.isValid) {
      return createFreeUser(userValues, activePackage)
        .then((response) => {
          if (response.signup_session_token) {
            dispatch({ type: ACTIONS.CREATE_USER_SUCCESS, payload: response });
            handleAnalytics(response, userValues, activePackage, activeIsFree);
            setCookie(COOKIES.MAKO_AUTH, response.authorization_token, {
              secure: true
            });

            if (isPackageAds(activePackage)) {
              history.push('/ads/billing');
            } else if (isPaymentRequired(activePackage)) {
              history.push('/billing');
            } else {
              history.push('/account_details');
            }
          } else {
            const passwordError =
              response.errors &&
              response.errors.find((error) => error.field === 'password');
            if (passwordError && onPasswordError) {
              dispatch({ type: ACTIONS.CREATE_USER_ERROR_PASSWORD });
              dispatch({
                type: APPINDICATORS.DISPLAY_ALERT,
                alertType: APPINDICATORS.ALERT_TYPES.DANGER,
                alertText: APPINDICATORS.ALERT_TEXT.CREATE_USER_ERROR_PASSWORD
              });
              onPasswordError(passwordError.message);
            } else {
              dispatch({ type: ACTIONS.CREATE_USER_ERROR });
              dispatch({
                type: APPINDICATORS.DISPLAY_ALERT,
                alertType: APPINDICATORS.ALERT_TYPES.DANGER,
                alertText: response.errorText
                  ? response.errorText
                  : APPINDICATORS.ALERT_TEXT.USER_CREATION_ERROR
              });
            }
          }
        })
        .catch((error) => {
          serviceDownError(error, dispatch);
        })
        .finally(() => {
          dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED });
        });
    }
    dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED });
    return null;
  };
