export default {
  TAGS: {
    CREATE_ACCOUNT: 'Create Account',
    PROVISION: 'Provision',
    ZUORA: 'Zuora',
    SIGNUP_TOKEN: 'Signup Token',
    BROWSER_FINGERPRINT: 'Browser Fingerprint',
  },
  LEVELS: {
    FATAL: 'Fatal',
    ERROR: 'Error',
    WARNING: 'Warning',
    INFO: 'Info',
    DEBUG: 'Debug',
  },
  TAG_VALUES: {
    SIGNUP_TOKEN_FAILURE: 'Signup token failure',
    PAYMENT_FORM_PARAMETERS: 'Payment form parameters',
    PROVISION_FAILURE: 'Provision failure',
    SIGNUP_FAILURE: 'Signup failure',
    BOSS_PAYMENT_ERROR: 'Boss account or processing payment',
    FORM_ERROR: 'Form Error',
    PAYMENT_ERROR: 'Payment Error',
    BROWSER_FINGERPRINT_ERROR: 'Browser Fingerprint Error',
  },
  ERROR_MESSAGES: {
    ZUORA_PAYMENT_FORM: 'Something went wrong with requesting payment form parameters',
    ZUORA_FORM_ERROR: 'Error loading payment form',
    NO_MESSAGE: 'No error message to provide',
  },
};
