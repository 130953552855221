import React from 'react';
import { analytics } from '@sendgrid/bloodhound';
import { Button } from '@sendgrid/ui-components/button';
import Layout from '../components/Layout';
import { SignupDeclinedEvents } from '../analytics/signupDeclinedEvents';

const Declined = () => (
  <Layout
    title="Please provide further information"
    subtitle="You will not be able to send email through SendGrid until you provide more information.  Please contact Support to answer a few more questions."
  >
    <center>
      <Button
        type="secondary"
        onClick={() => {
          analytics.track(SignupDeclinedEvents.contactSupportClicked());
          window.location.assign('https://support.sendgrid.com/hc/en-us/requests/new#account-access-issue');
        }}
        data-role="contact-support-button"
        loading={false}
      >
            Contact Support
      </Button>
    </center>
  </Layout>
);


export default Declined;
