import React from 'react';
import { Card } from '@sendgrid/ui-components/card';
const Styles = require('./jobsToBeDoneCard.module.scss');
const PaperAirplane = require('../images/paper-airplane.svg');

const JobsToBeDoneCard = () => (
  <div className={Styles['jobs-to-be-done-container']}>
    <Card className={Styles['jobs-to-be-done-card']}>
      <h2>Try it out!</h2>
      <p>Send email for free.</p>
      <ul>
        <li className="is-size-h3">Automated drip campaigns</li>
        <li className="is-size-h3">Password resets</li>
        <li className="is-size-h3">Newsletters</li>
        <li className="is-size-h3">Receipts</li>
        <li className="is-size-h3">Delivery notifications and updates</li>
        <li className="is-size-h3">Promoted emails</li>
      </ul>
      <img
        className={Styles['jobs-to-be-done-img']}
        src={PaperAirplane}
        alt="Paper Airplane"
      />
    </Card>
  </div>
);

export default JobsToBeDoneCard;
