import { ACTIONS } from '../constants';

const INITIAL_STATE = {
  formParams: {},
  prepopulateFields: {},
  dataLoaded: false,
};

const paymentForm = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.GET_PAYMENT_FORM:
      return {
        ...state,
        formParams: action.formParams,
        prepopulateFields: action.prepopulateFields,
        dataLoaded: action.dataLoaded,

      };
    default:
      return state;
  }
};

export default paymentForm;
