import React, { useRef } from 'react';
import { analytics } from '@sendgrid/bloodhound';
import PropTypes from 'prop-types';
import Styles from './multisidebar.module.scss';
import { MultiProductCard } from './MultiProductCard';
import { TotalCard } from './TotalCard';
import { AddOnCard } from './AddOnCard';
import { YourProductsEvents } from '../../analytics/yourProductsEvents';
import JobsToBeDoneCard from '../JobsToBeDoneCard';

export const MultiSideBar = ({ activePackage, addOns }) => {
  const pricingLinkRef = useRef();
  if (pricingLinkRef.current) {
    analytics.trackLink({
      ...YourProductsEvents.pricingLinkClicked(),
      element: pricingLinkRef.current,
    });
  }

  const isAwsSignup = activePackage.some(pkg => pkg.is_aws);

  return (
    <aside className={Styles.sidenav}>
      <section className={Styles.sidenavContainer}>
        {!isAwsSignup && (
          <JobsToBeDoneCard />
        )}
        {isAwsSignup
          && (
          <div>
            <div>
              <h2>Your Products</h2>
            </div>
            {activePackage.map(product => (
              <MultiProductCard
                key={product.id}
                product={product}
              />
            ))}
            {(addOns.ip > 0 || addOns.ease > 3)
              && (
                <AddOnCard
                  addOns={addOns}
                />
              )
            }
            <TotalCard
              activePackage={activePackage}
              addOns={addOns}
            />
          </div>
          )
        }
      </section>
    </aside>
  );
};

MultiSideBar.propTypes = {
  addOns: PropTypes.shape({
    ease: PropTypes.number,
    ip: PropTypes.number,
  }).isRequired,
  activePackage: PropTypes.arrayOf(PropTypes.shape({
    adjusted_price: PropTypes.number,
    base_price: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default MultiSideBar;
