import React, { Fragment } from 'react';
import { COOKIES, TELEMETRY_ERROR_VALUES } from '../constants';
import createRequest from './requestService';
import { getCookie } from './cookieHelpers';
import {
  getEmailApiPlan, getMarketingCampaignsPlan, getAwsPlan, getAdsPlan,
} from './packageHelper';
import packageConfig from './packageConfig.js';
import { telemetry } from './telemetry';

const { emailApiDefaultPackage, marketingCampaignsDefaultPackage } = packageConfig

const getAffiliate = () => {
  const cjDetailsCookie = getCookie(COOKIES.CJ_DETAILS);

  if (cjDetailsCookie) {
    const json = JSON.parse(cjDetailsCookie);
    if (json.cj_sourced) {
      return 'cj';
    }
  }
  return null;
};

const signUpUser = (url, body) => (
  fetch(createRequest(url, { body }))
    .then((response) => {
      const {
        TAGS, TAG_VALUES, LEVELS, ERROR_MESSAGES,
      } = TELEMETRY_ERROR_VALUES;
      if (response.ok || response.status === 400) {
        return response.json();
      }
      return response.json()
        .then((data) => {
          telemetry.captureError(
            data.errors[0].message,
            TAGS.CREATE_ACCOUNT,
            TAG_VALUES.SIGNUP_FAILURE,
            LEVELS.FATAL,
          );
          if (data.errors.length) {
            return data;
          }
          throw new Error('Unexpected sign up failure');
        })
        .catch(() => {
          telemetry.captureError(
            ERROR_MESSAGES.NO_MESSAGE,
            TAGS.CREATE_ACCOUNT,
            TAG_VALUES.SIGNUP_FAILURE,
            LEVELS.FATAL,
          );
        });
    })
);

const createFreeUser = (userValues, activePackage) => {
  let url = '/v3/public/s2s/signup';

  const baseBody = {
    ...userValues,
    // I believe this is created before user gets to signup app
    signup_bi: getCookie(COOKIES.SF_DETAILS),
    // I believe this is created before user gets to signup app
    sendgrid_partner: getCookie(COOKIES.SENDGRID_PARTNER),
    signup_session_token: getCookie(COOKIES.SIGNUP_SESSION),
    // is_cutoff_trial: 1, // isCutoffTrial set in https://github.com/sendgrid/mako/blob/dfe9d40807065cd893499c0ff6bc331174afe35f/src/router.js
  };

  const affiliate = getAffiliate();
  if (affiliate) {
    baseBody.affiliate = affiliate;
  }

  // AWS sign up
  const awsPackage = getAwsPlan(activePackage);
  if (awsPackage) {
    if (awsPackage.cuuid) {
      url = '/v3/public/s2s/signup/partner';
    } else {
      /* eslint-disable react/jsx-indent */
      return Promise.resolve({
        errorText: <Fragment>
          We couldnt find your AWS account. Please visit the
          <a href="https://aws.amazon.com/marketplace/pp/B074CQY6KB">AWS Marketplace</a>
          {' '}
          and try to sign up again.
                   </Fragment>,
      });
    }
    const body = {
      ...baseBody,
      package_uuid: awsPackage.id,
      cuuid: awsPackage.cuuid,
    };
    return signUpUser(url, body)
      .then(data => ({
        ...data,
        combo_package_uuid: awsPackage.id,
      }));
  }

  // Ads sign up
  const adsPackage = getAdsPlan(activePackage);
  if (adsPackage) {
    const body = {
      ...baseBody,
      package_uuid: adsPackage.id,
    };
    return signUpUser(url, body)
      .then(data => ({
        ...data,
        combo_package_uuid: adsPackage.id,
      }));
  }

  // Regular sign up
  const eiPlan = getEmailApiPlan(activePackage) || emailApiDefaultPackage;
  const mcPlan = getMarketingCampaignsPlan(activePackage) || marketingCampaignsDefaultPackage;

  const body = {
    ...baseBody,
    ei_plan_name: eiPlan.id,
    mc_plan_name: mcPlan.id,
  };
  return signUpUser(url, body);
};

export default createFreeUser;
