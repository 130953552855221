import {
  Page, Section, SubSection, ElementType, EventType,
} from './constants';

export const AccountDetailsEvents = {
  firstNameCompleted: () => ({
    event: 'first name completed',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  lastNameCompleted: () => ({
    event: 'last name completed',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  companyNameCompleted: () => ({
    event: 'company name completed',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  companyWebsiteCompleted: () => ({
    event: 'company website completed',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  countryCodeCompleted: () => ({
    event: 'country code completed',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
      elementType: ElementType.input,
      eventType: EventType.click,
    },
  }),
  phoneNumberCompleted: () => ({
    event: 'phone number completed',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  accountBanned: () => ({
    event: 'account banned',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
    },
  }),
  accountProvisioned: () => ({
    event: 'account provisioned',
    properties: {
      page: Page.accountDetails,
      section: Section.tellUsAboutYourself,
    },
  }),
  easeAdded: easeAddon => ({
    event: 'ease added',
    properties: {
      page: Page.accountInputs,
      section: Section.tellUsAboutYourself,
      easeAddon,
    },
  }),
  ipAdded: ipAddon => ({
    event: 'ips added',
    properties: {
      page: Page.accountInputs,
      section: Section.tellUsAboutYourself,
      ipAddon,
    },
  }),
};
