import validationRules from './validationRules';

export default {
  NO_TYPE_SET: '',
  EMAIL_DEFAULT: `Your email address must have more than ${validationRules.EMAIL_LENGTH_MIN} and fewer than ${validationRules.EMAIL_LENGTH_MAX} characters.`,
  EMAIL_INVALID: 'The email address you entered is not valid.',
  EMAIL_SHORT: `Your email address must have more than ${validationRules.EMAIL_LENGTH_MIN} characters.`,
  EMAIL_LONG: `Your email address must have fewer than ${validationRules.EMAIL_LENGTH_MAX} characters.`,
  EMAIL_USERNAME_TAKEN: 'This email address is already used as a username. Try a different email address or create a unique username.',
  USERNAME_PREFIX: 'Your username must',
  USERNAME_DEFAULT: ` have more than ${validationRules.USERNAME_LENGTH_MIN} and fewer than ${validationRules.USERNAME_LENGTH_MAX} characters, including at least 1 letter.`,
  USERNAME_SHORT: ` have more than ${validationRules.USERNAME_LENGTH_MIN} characters`,
  USERNAME_LONG: ` have fewer than ${validationRules.USERNAME_LENGTH_MAX} characters`,
  USERNAME_NO_LETTER: ' have at least 1 letter',
  USERNAME_SENDGRID_DOMAIN: ' not have SendGrid domain endings',
  USERNAME_SPACE: ' not have any spaces',
  USERNAME_EMAIL_ADDRESS: ' not be an email address',
  USERNAME_INVALID_CHARACTERS: ' not have any invalid characters',
  USERNAME_INVALID: 'Your username must not have any invalid characters',
  PASSWORD_PREFIX: 'Your password must',
  PASSWORD_PREFIX_FLEXIBLE: 'Your password',
  PASSWORD_SHORT: ` have at least ${validationRules.PASSWORD_LENGTH_MIN} characters`,
  PASSWORD_NO_DIGIT: ' have at least 1 number',
  PASSWORD_NO_LETTER: ' have at least 1 letter',
  PASSWORD_NON_ASCII: ' have only letters, numbers, and ASCII characters',
  PASSWORD_LONG: ` cannot have more than ${validationRules.PASSWORD_LENGTH_MAX} characters`,
  PASSWORD_NO_MATCH: 'Your passwords do not match.',
  PASSWORD_MATCH_USERNAME: ' be different than your username',
  PASSWORD_CONFIRM: 'Please confirm your password.',
  USERNAME_TAKEN: 'That username is taken. Please try another.',
  USERNAME_AVAILABLE: 'That username is available!',
  USERNAME_TOO_MANY_REQUESTS: 'Too many requests. Please try again in a few minutes.',
  CAPTCHA_INVALID: 'Please verify that you’re not a robot. Beep boop.',
  NAME_ONLY_NON_NUMERIC: 'Your name must have at least 1 letter.',
  NAME_CHAR_LIMIT: `Your name must be between ${validationRules.NAME_LENGTH_MIN} and ${validationRules.NAME_LENGTH_MAX} characters.`,
  COMPANY_NAME_CHAR_LIMIT: `Company name must be between ${validationRules.COMPANY_NAME_MIN} and ${validationRules.COMPANY_NAME_MAX} characters.`,
  FIELD_REQUIRED: 'This field is required.',
  PHONE_CHAR_LIMIT: `Your phone number must have between ${validationRules.PHONE_LENGTH_MIN} and ${validationRules.PHONE_LENGTH_MAX} characters.`,
  WEBSITE_CHAR_LIMIT: `Website must be between ${validationRules.WEBSITE_LENGTH_MIN} and ${validationRules.WEBSITE_LENGTH_MAX} characters.`,
};
