import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Z from './zuora';
import { getPaymentForm, zuoraCallback, logPaymentProviderError } from '../actions';
import withAccountInputs from '../containers/withAccountInputsV1';

const ZuoraErrorKey = {
  error: 'error',
};

const ZuoraErrorCode = {
  unknown: 'unknown',
};

export class PaymentForm extends React.Component {
  componentDidMount() {
    this.props.getPaymentForm(this.props.history);
  }

  componentDidUpdate() {
    if (this.props.paymentForm.dataLoaded) {
      Z.renderWithErrorHandler(
        this.props.paymentForm.formParams,
        this.props.paymentForm.prepopulateFields,
        (response) => { this.props.zuoraCallback(response, this.props.history); },
        (key, code, error) => { this.handlePaymentError(key, code, error); },
      );
    }
  }

  handlePaymentError(key, code, message) {
    // Since the payment form submission happens on Zuora's servers, we don't
    // have logs that we can report on. This endpoint logs payment provider
    // errors so we can generate alerts from them.
    const isServerError = key.toLowerCase() === ZuoraErrorKey.error
      && code.toLowerCase() === ZuoraErrorCode.unknown;
    if (isServerError) {
      this.props.logPaymentProviderError(message);
    }

    // Custom error handling can be implemented here to override default messages
    // provided by Zuora. This method sends any custom messages to the form.
    Z.sendErrorMessageToHpm(key, message);
  }

  render() {
    return (
      <div className="zuora-container">
        <div id="zuora_payment" data-role="zuora-payment" />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPaymentForm: (history) => {
    dispatch(getPaymentForm(history));
  },
  zuoraCallback: (response, history) => {
    dispatch(zuoraCallback(response, history));
  },
  logPaymentProviderError: (message) => {
    dispatch(logPaymentProviderError(message));
  },
});

function mapStateToProps({ paymentForm }) {
  return { paymentForm };
}

PaymentForm.propTypes = {
  getPaymentForm: PropTypes.func.isRequired,
  paymentForm: PropTypes.shape({
    dataLoaded: PropTypes.bool.isRequired,
    formParams: PropTypes.object,
    prepopulateFields: PropTypes.object,
  }).isRequired,
  zuoraCallback: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  logPaymentProviderError: PropTypes.func.isRequired,
};

const ConnectedPaymentForm = withRouter(withAccountInputs(PaymentForm));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedPaymentForm);
