import { ACTIONS } from '../constants';
import {
  validateEmail,
  validateUsername,
  validatePassword,
  validateConfirmPassword,
  checkMatching
} from '../utils/formInput';
import errorTypes from '../constants/errorTypes';

const INITIAL_STATE = {
  username_value: '',
  username_isValid: true,
  username_errorType: errorTypes.NO_TYPE_SET,
  password_value: '',
  password_isValid: true,
  password_errorType: errorTypes.NO_TYPE_SET,
  confirmPassword_value: '',
  confirmPassword_isValid: true,
  confirmPassword_errorType: errorTypes.NO_TYPE_SET,
  email_value: '',
  email_isValid: true,
  email_errorType: errorTypes.NO_TYPE_SET,
  username_unique_value: '',
  username_unique_isValid: true,
  username_unique_errorType: errorTypes.NO_TYPE_SET,
  form_isValid: false,
  captcha_errorType: errorTypes.NO_TYPE_SET,
  captcha_isValid: false,
  signup_session_token: '',
  tos: 0,
  recaptcha_token: ''
};

const validateFields = (...fields) => {
  const isTrue = (field) => field === true;
  return fields.every(isTrue);
};

const checkFields = (...fields) => {
  const isNotEmpty = (field) => field !== '';
  return fields.every(isNotEmpty);
};

const isValidForm = (newState) =>
  checkFields(
    newState.username_value,
    newState.password_value,
    newState.confirmPassword_value,
    newState.email_value
  ) &&
  validateFields(
    newState.username_isValid,
    newState.password_isValid,
    newState.confirmPassword_isValid,
    newState.email_isValid,
    newState.username_unique_isValid,
    newState.tos === 1
  );

const formInputs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_USERNAME: {
      const responseUsername = validateUsername(action.username);
      const usernameAndPasswordMatch = checkMatching(
        action.username,
        state.password_value
      );
      const newState = {
        ...state,
        username_unique_isValid: true,
        username_value: action.username,
        username_isValid: responseUsername.isValid,
        username_errorType: responseUsername.errorType,
        password_errorType: usernameAndPasswordMatch
          ? `${errorTypes.PASSWORD_PREFIX}${errorTypes.PASSWORD_MATCH_USERNAME}`
          : state.password_errorType,
        password_isValid: usernameAndPasswordMatch
          ? false
          : state.password_isValid
      };
      return {
        ...newState,
        form_isValid: isValidForm(newState)
      };
    }

    case ACTIONS.SET_PASSWORD: {
      const responsePassword = validatePassword(
        action.password,
        state.confirmPassword_value,
        state.username_value
      );
      const newState = {
        ...state,
        password_value: action.password,
        password_isValid: responsePassword.isValid,
        password_errorType: responsePassword.errorType,
        confirmPassword_errorType: responsePassword.confirmPassword_errorType,
        confirmPassword_isValid: responsePassword.confirmPassword_isValid
      };
      return {
        ...newState,
        form_isValid: isValidForm(newState)
      };
    }

    case ACTIONS.SET_CONFIRM_PASSWORD: {
      const responseConfirmPassword = validateConfirmPassword(
        action.confirmPassword,
        state.password_value
      );
      const newState = {
        ...state,
        confirmPassword_value: action.confirmPassword,
        confirmPassword_isValid: responseConfirmPassword.isValid,
        confirmPassword_errorType: responseConfirmPassword.errorType
      };
      return {
        ...newState,
        form_isValid: isValidForm(newState)
      };
    }

    case ACTIONS.SET_EMAIL: {
      const responseEmail = validateEmail(action.email);
      const newState = {
        ...state,
        email_value: action.email,
        email_isValid: responseEmail.isValid,
        email_errorType: responseEmail.errorType
      };
      return {
        ...newState,
        form_isValid: isValidForm(newState)
      };
    }

    // This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
    case ACTIONS.USERNAME_EXISTS_CHECK: {
      const newState = {
        ...state,
        username_unique_value: action.username_unique_value,
        username_unique_isValid: action.username_unique_isValid,
        username_unique_errorType: action.username_unique_errorType
      };
      return {
        ...newState,
        form_isValid: isValidForm(newState)
      };
    }

    // This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
    case ACTIONS.POST_RECAPTCHA: {
      const newState = {
        ...state,
        captcha_isValid: action.captcha_isValid,
        captcha_errorType: action.captcha_errorType,
        signup_session_token: action.signup_session_token
      };
      return {
        ...newState,
        form_isValid: isValidForm(newState)
      };
    }

    // This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
    case ACTIONS.CREATE_USER_SUCCESS: {
      const newState = {
        ...INITIAL_STATE,
        email_value: state.email_value
      };
      return {
        ...newState
      };
    }

    // This should be moved to /AccountInputsV1 whenever the aws form (AccountInputsV1) is converted to the new form (AccountInputsV2)
    case ACTIONS.TOGGLE_TOS: {
      const newState = {
        ...state,
        tos: 1 - state.tos
      };
      return {
        ...newState,
        form_isValid: isValidForm(newState)
      };
    }

    // This should be consolidated with hooks/AccountInputs if the AWS form (AccountInputsV1) is converted to the new form (AccountInputsV2)
    case ACTIONS.SET_RECAPTCHA_TOKEN: {
      return {
        ...state,
        recaptcha_token: action.recaptcha_token
      };
    }

    default:
      return state;
  }
};

export default formInputs;
