import createRequest from "../utils/requestService";

export const getUserProfile = () => {
  const request = createRequest('/v3/user/profile', {method: 'GET'});
  return fetch(request);
}

export const getUserEmail = () => {
  const request = createRequest('/v3/user/email', {method: 'GET'});
  return fetch(request);
}