import errorTypes from '../constants/errorTypes';
import validationRules from '../constants/validationRules';

// First name is required, must be non-numeric, and must be between 1 and 64 characters
export function validateFirstName(name) {
  const trimmedName = name.trim()
    
  if (trimmedName === '') {
    return errorTypes.FIELD_REQUIRED;
  } if (!/[a-zA-Z]/.test(trimmedName)) {
    return errorTypes.NAME_ONLY_NON_NUMERIC;
  } if (trimmedName.length < validationRules.NAME_LENGTH_MIN
    || name.length > validationRules.NAME_LENGTH_MAX) {
    return errorTypes.NAME_CHAR_LIMIT;
  }

  return null;
}

// Last name is optional, must be non-numeric, and must be under 64 characters
export function validateLastName(name) {
  const trimmedName = name.trim()

  if (trimmedName !== '' && !/[a-zA-Z]/.test(trimmedName)) {
    return errorTypes.NAME_ONLY_NON_NUMERIC;
  } if (name.length > validationRules.NAME_LENGTH_MAX) {
    return errorTypes.NAME_CHAR_LIMIT;
  }

  return null; 
}

function trimName(name, length) {
  return name.substring(0, length);
}

export function trimCompanyName(companyName) {
  return trimName(companyName, validationRules.COMPANY_NAME_MAX);
}

export function validateCompanyName(companyName) {
  const trimmedCompany = companyName.trim()
  if (trimmedCompany === '') {
    return errorTypes.FIELD_REQUIRED;
  }
  if (trimmedCompany.length < validationRules.COMPANY_NAME_MIN
    || companyName.length > validationRules.COMPANY_NAME_MAX) {
    return errorTypes.COMPANY_NAME_CHAR_LIMIT;
  }
  return null;
}

export function validPhoneNumber(phoneNumber) {
  const trimmedPhone = phoneNumber.trim();
  return trimmedPhone.replace(/\D/g, '');
}

export function validatePhoneNumber(phoneNumber) {
  const trimmedNumber = phoneNumber.trim()
  if (trimmedNumber === '') {
    return errorTypes.FIELD_REQUIRED;
  }
  if (trimmedNumber.length < validationRules.PHONE_LENGTH_MIN
    || phoneNumber.length > validationRules.PHONE_LENGTH_MAX) {
    return errorTypes.PHONE_CHAR_LIMIT;
  }
  return null;
}

export function trimWebsite(website) {
  return trimName(website, validationRules.WEBSITE_LENGTH_MAX);
}

export function validateWebsite(website) {
  const trimmedWebsite = website.trim()
  if (trimmedWebsite === '') {
    return errorTypes.FIELD_REQUIRED;
  }
  if (trimmedWebsite.length < validationRules.WEBSITE_LENGTH_MIN
    || website.length > validationRules.WEBSITE_LENGTH_MAX) {
    return errorTypes.WEBSITE_CHAR_LIMIT;
  }
  return null;
}
