export default {
  "hiddenPackages": {
    "02a5084c-94d7-458d-9a88-1d2cfd37b262": "Free 100",
    "8b9ae93b-ce8a-11e4-b4e5-5fcde71ee009": "Free 12K",
    "fddb569d-5745-48cd-af8c-e78ba8e3b478": "Pro 2.5 Million",
    "b545e102-055d-4c7e-9b3e-fe09e538f384": "Pro 2.5 Million Legacy",
    "8b9ce1c9-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 40K ($9.95)",
    "8b9cf53b-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 100K ($19.95)"
  },
  "freePackages": {
    "8ad8c03d-7f87-4fb7-8767-87454519efdc": "AWS Pro",
    "561a4203-518a-4ebc-a4f8-3100d4e810ff": "AWS Pre-Pro",
    "ba389e91-2ac8-41e3-8dcf-d734aea21ff8": "AWS Basic",
    "8b9b079e-ce8a-11e4-b4e5-5fcde71ee009": "Free Package GitHub Education"
  },
  "proPackages": {
    "8b9d04af-ce8a-11e4-b4e5-5fcde71ee009": "Pro 100K",
    "8b9d0cf5-ce8a-11e4-b4e5-5fcde71ee009": "Pro 300K",
    "8b9d1f56-ce8a-11e4-b4e5-5fcde71ee009": "Pro 700K",
    "0c37e79f-8c17-453a-b72d-061ca2fa5c54": "Pro 1.5 Million"
  },
  "adsPackages": {
    "76559ee6-9cbc-42ee-abb7-02efb53672f5": "Ads",
    "97982829-c3ea-40af-b989-e9905943c0d5": "Ads",
    "1c139ecc-7717-4037-ac53-e9377cb58adc": "Ads"
  },
  "awsPackages": {
    "pro": {
      "adjusted_price": 80.0,
      "base_price": 80.0,
      "credits": 0,
      "description": "",
      "id": "561a4203-518a-4ebc-a4f8-3100d4e810ff",
      "name": "AWS Pro",
      "is_aws": true,
      "newsletter_price": 0.0,
      "overage_price": 0.0,
      "plan_type": "Email API",
      "total": 80.0
    },
    "basic": {
      "adjusted_price": 10.0,
      "base_price": 10.0,
      "credits": 0,
      "description": "",
      "id": "ba389e91-2ac8-41e3-8dcf-d734aea21ff8",
      "name": "AWS Basic",
      "is_aws": true,
      "newsletter_price": 0.0,
      "overage_price": 0.0,
      "plan_type": "Email API",
      "total": 10.0
    }
  },
  "adsDefaultPackage": {
    "adjusted_price": 50.0,
    "base_price": 50.0,
    "credits": 0,
    "description": "",
    "name": "Ads",
    "is_aws": false,
    "newsletter_price": 0.0,
    "overage_price": 0.0,
    "plan_type": "Email API",
    "total": 50.0
  },
  "overage_rates": {
    "Essentials 100K": 0.00075,
    "Pro 300K": 0.00050,
    "Pro 700K": 0.00045
  },
  "emailApiDefaultPackage": {
    "id": "sg.ei.free-100.v1",
    "name": "Free 100",
    "base_price": 0,
    "adjusted_price": 0,
    "plan_type": "Email API"
  },
  "marketingCampaignsDefaultPackage": {
    "id": "sg.mc.free.v1",
    "name": "Free",
    "base_price": 0,
    "adjusted_price": 0,
    "plan_type": "Marketing Campaigns"
  }
}
