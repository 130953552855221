import React from 'react';
import PropTypes from 'prop-types';
import Styles from './sidebar.module.scss';
import AdsSummaryCard from './AdsSummaryCards';
import accountTypes from '../constants/accountTypes';

const SummarySideBar = ({ sideBarType }) => (
  <aside className={Styles.sidenav}>
    {sideBarType === accountTypes.ads
      && <AdsSummaryCard />
    }
  </aside>
);

SummarySideBar.propTypes = {
  sideBarType: PropTypes.string.isRequired,
};

export default SummarySideBar;
