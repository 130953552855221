// Some of these values are not inclusive due to
// the way the validation messages are phrased.
// e.g. "Must have at least 16 characters" to
// represent an inclusive minimum of 16 characters.
export default {
  PASSWORD_LENGTH_MIN: 16,
  PASSWORD_LENGTH_MAX: 128,
  USERNAME_LENGTH_MIN: 3,
  USERNAME_LENGTH_MAX: 64,
  EMAIL_LENGTH_MIN: 5,
  EMAIL_LENGTH_MAX: 256,
  NAME_LENGTH_MIN: 1,
  NAME_LENGTH_MAX: 64,
  PHONE_LENGTH_MIN: 2,
  PHONE_LENGTH_MAX: 32,
  COMPANY_NAME_MIN: 2,
  COMPANY_NAME_MAX: 255,
  WEBSITE_LENGTH_MIN: 2,
  WEBSITE_LENGTH_MAX: 255,
};
