import React from 'react';

import { COOKIES, ENVIRONMENT } from '../constants';
import ProfileForm from '../components/ProfileForm';
import Layout from '../components/Layout';
import getEnvVariable from '../utils/environment';
import { getCookie } from '../utils/cookieHelpers';

class Profile extends React.Component {
  componentDidMount() {
    if (!getCookie(COOKIES.MAKO_AUTH)) {
      window.location.replace(`${getEnvVariable(ENVIRONMENT.MAKO_URL)}/login?redirect_to=signup.sendgrid.com%2Faccount_details`);
    }
  }

  render() {
    return (
      <Layout title="Tell Us About Yourself" subtitle="This information will help us serve you better.">
        <ProfileForm />
      </Layout>
    );
  }
}

export default Profile;
