import { selectSelectorGenerator, writeSelectorGenerator } from '../qa-hooks/typedSelectorHelpers';

const QaHooks = {};

const hooks = { 
  firstNameInput: 'firstNameInput',
  lastNameInput: 'lastNameInput',
  companyNameInput: 'companyNameInput',
  websiteInput: 'websiteInput',
  companyEmailInput: 'companyEmailInput',
  countryCodeSelect: 'countryCodeSelect',
  phoneInput: 'phoneInput',
};

const Selectors = { ...selectSelectorGenerator(hooks), ...QaHooks };
const WriteSelectors = writeSelectorGenerator(hooks);

export { Selectors, WriteSelectors };