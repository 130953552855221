import { connect } from 'react-redux';
import {
  setUsername,
  setEmail,
  blurEmail,
  setPassword,
  blurPassword,
  setConfirmPassword,
  blurConfirmPassword,
  usernameExistsCheck,
  postRecaptcha,
  createUser,
  toggleTos,
  setRecaptchaToken
} from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setUsername: (e, username) => {
    dispatch(setUsername(username));
  },
  setPassword: (e, password) => {
    dispatch(setPassword(password));
  },
  blurPassword: (e, password) => {
    dispatch(blurPassword(password));
  },
  setConfirmPassword: (e, confirmPassword) => {
    dispatch(setConfirmPassword(confirmPassword));
  },
  blurConfirmPassword: (e, confirmPassword) => {
    dispatch(blurConfirmPassword(confirmPassword));
  },
  setEmail: (e, email) => {
    dispatch(setEmail(email));
  },
  toggleTos: () => {
    dispatch(toggleTos());
  },
  blurEmail: (e, email) => {
    dispatch(blurEmail(email));
  },
  usernameExistsCheck: (e, username) => {
    dispatch(usernameExistsCheck(username));
  },
  postRecaptcha: (recaptcha) => {
    dispatch(postRecaptcha(recaptcha));
  },
  setRecaptchaToken: (recaptchaToken) => {
    dispatch(setRecaptchaToken(recaptchaToken));
  },
  createUser: (
    userValues,
    activePackage,
    activeIsFree,
    history,
    canBeEmail = false,
    onPasswordError
  ) => {
    dispatch(
      createUser(
        userValues,
        activePackage,
        activeIsFree,
        history,
        canBeEmail,
        onPasswordError
      )
    );
  }
});

function mapStateToProps({ formInputs }) {
  return { formInputs };
}

export default (ComposedCmp) =>
  connect(mapStateToProps, mapDispatchToProps)(ComposedCmp);
