import {
  Page, Section, SubSection, ElementType, EventType,
} from './constants';

export const AccountInputsEvents = {
  usernameCompleted: () => ({
    event: 'username completed',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  passwordCompleted: () => ({
    event: 'password completed',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  confirmPasswordCompleted: () => ({
    event: 'confirm password completed',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  emailAddressCompleted: () => ({
    event: 'email address completed',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.input,
      eventType: EventType.blur,
    },
  }),
  recaptchaCompleted: isValid => ({
    event: 'recaptcha completed',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      subSection: SubSection.recaptcha,
      elementType: ElementType.captcha,
      isValid,
    },
  }),
  termsOfServiceAccepted: () => ({
    event: 'terms of service accepted',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.checkbox,
      eventType: EventType.click,
    },
  }),
  termsOfServiceClicked: () => ({
    event: 'terms of service clicked',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.link,
      eventType: EventType.click,
    },
  }),
  privacyNoticeClicked: () => ({
    // The naming misalignment was caused by a copy change from "policy" -> "notice".
    // Event name was left as is to prevent any downstream affects. The data team
    // filters on event names in order to aggregate data.
    event: 'privacy policy clicked',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.link,
      eventType: EventType.click,
    },
  }),
  accountCreated: () => ({
    event: 'account created',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
    },
  }),
  overageRatesClicked: () => ({
    event: 'overage rates clicked',
    properties: {
      page: Page.accountInputs,
      section: Section.yourProducts,
      subSection: SubSection.totalPrice,
      elementType: ElementType.link,
      eventType: EventType.click,
    },
  }),
  useEmailAddressToggled: {
    event: 'use email address as username toggled',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.checkbox,
      eventType: EventType.click,
    },
  },
  revealPasswordToggled: {
    event: 'reveal password toggled',
    properties: {
      page: Page.accountInputs,
      section: Section.letsGetStarted,
      elementType: ElementType.icon,
      eventType: EventType.click,
    },
  },
};
