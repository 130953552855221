import { ACTIONS, LOCALSTORAGE } from '../constants';
import { calculateTotalPrice } from '../utils/displayHelpers';

const initialState = {
  comboPackageId: '',
  activePackage: [],
  activeIsFree: true,
  packagesLoaded: false,
};

const getInitialState = () => {
  const cachedState = localStorage && localStorage.getItem(LOCALSTORAGE.REDUX_STATE);

  if (cachedState) {
    const parsedCachedState = JSON.parse(cachedState);

    if (parsedCachedState && parsedCachedState.packages) {
      return parsedCachedState.packages;
    }
  }

  return initialState;
};

const packages = (state = getInitialState(), action) => {
  switch (action.type) {
    case ACTIONS.GET_PACKAGES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        packagesLoaded: true,
      };
    case ACTIONS.GET_PACKAGES_FAILURE:
      return {
        ...initialState,
        packagesLoaded: true,
      };
    case ACTIONS.CREATE_USER_SUCCESS:
      return {
        ...state,
        comboPackageId: action.payload && action.payload.combo_package_uuid,
      };
    case ACTIONS.SET_PACKAGE: {
      const activePackage = action.activePackage || [];
      return {
        ...state,
        activePackage,
        activeIsFree: calculateTotalPrice(activePackage) === 0,
        packagesLoaded: true,
      };
    }
    default:
      return state;
  }
};

export default packages;
