import {
    selectSelectorGenerator,
    writeSelectorGenerator,
  } from '../../qa-hooks/typedSelectorHelpers';
  
  const QaHooks = {};
  
  const hooks = {
    signupContainer: 'signupContainer',
    signupAlert: 'signupAlert' 
  };
  
  const Selectors = { ...selectSelectorGenerator(hooks), ...QaHooks };
  const WriteSelectors = writeSelectorGenerator(hooks);
  
  export { Selectors, WriteSelectors };