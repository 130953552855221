export const EventType = {
  blur: 'blur',
  post: 'post',
  click: 'click',
};

export const Page = {
  accountInputs: 'account inputs',
  billing: 'billing',
  accountDetails: 'account details',
  declined: 'declined',
  resellerGoogle: 'reseller google',
};

export const Section = {
  paymentForm: 'payment form',
  letsGetStarted: 'lets get started',
  yourProducts: 'your products',
  tellUsAboutYourself: 'tell us about yourself',
  provideFurtherInfo: 'provide further info',
  googleSignupForm: 'google signup form',
  googleSignupSuccess: 'google signup success',
};

export const SubSection = {
  recaptcha: 'recaptcha',
  totalPrice: 'total price',
  noProducts: 'no products',
};

export const ElementType = {
  input: 'input',
  captcha: 'captcha',
  link: 'link',
  checkbox: 'checkbox',
  button: 'button',
  icon: 'icon',
};
