import { analytics } from '@sendgrid/bloodhound';
import {
  ACTIONS,
  ADDONS,
  COOKIES,
  ERRORTYPES,
  APPINDICATORS,
  ENVIRONMENT,
  LOCALSTORAGE,
  TELEMETRY_ERROR_VALUES
} from '../constants';
import {
  validateFirstName,
  validateLastName,
  validPhoneNumber,
  validatePhoneNumber,
  validateWebsite,
  validateCompanyName,
  trimCompanyName,
  trimWebsite
} from '../utils/profileInput';
import { validateEmail } from '../utils/formInput';
import createRequest from '../utils/requestService';
import getEnvVariable from '../utils/environment';
import { setCookie, removeCookie, getCookie } from '../utils/cookieHelpers';
import accountTypes from '../constants/accountTypes';
import { trackOnce } from '../utils/Analytics';
import { telemetry } from '../utils/telemetry';
import { AccountDetailsEvents } from '../analytics/accountDetailsEvents';
import { getUserProfile, getUserEmail } from '../service/getUserProfile';
import { isProfileComplete } from '../utils/isProfileComplete';
import { redirectToMako } from '../utils/redirectToMako';
import { getRawBrowserFingerprint } from '../utils/browserFingerprint';
import showFreeTrialOnboarding from '../utils/flags/showFreeTrialOnboarding';

const hasIP = (addOns) => addOns && addOns[ADDONS.DEDICATED_IP] > 0;

const hasEase = (addOns) => addOns && addOns[ADDONS.EASE] > 3;

const cleanUpSignUpSession = () => {
  removeCookie(COOKIES.SIGNUP_SESSION);
  localStorage.removeItem(LOCALSTORAGE.REDUX_STATE);
};

const trackFirstNameCompleted = trackOnce();
export const setFirstName = (firstName) => ({
  type: ACTIONS.SET_FIRST_NAME,
  firstName
});
export const blurFirstName = (firstName) => () => {
  const firstNameError = validateFirstName(firstName);
  if (!firstNameError) {
    trackFirstNameCompleted(AccountDetailsEvents.firstNameCompleted());
  }
};

const trackLastNameCompleted = trackOnce();
export const setLastName = (lastName) => ({
  type: ACTIONS.SET_LAST_NAME,
  lastName
});
export const blurLastName = (lastName) => () => {
  const lastNameError = validateLastName(lastName);
  if (!lastNameError) {
    trackLastNameCompleted(AccountDetailsEvents.lastNameCompleted());
  }
};

const trackPhoneNumberCompleted = trackOnce();
export const setPhoneNumber = (phoneNumber) => ({
  type: ACTIONS.SET_PHONE_NUMBER,
  phoneNumber
});
export const blurPhoneNumber = (phoneNumber) => () => {
  const phoneNumberVal = validPhoneNumber(phoneNumber);
  const phoneNumberError = validatePhoneNumber(phoneNumberVal);
  if (!phoneNumberError) {
    trackPhoneNumberCompleted(AccountDetailsEvents.phoneNumberCompleted());
  }
};

const trackCompanyCompleted = trackOnce();
export const setCompanyName = (companyName) => ({
  type: ACTIONS.SET_COMPANY_NAME,
  companyName
});
export const blurCompanyName = (companyName) => () => {
  const companyNameVal = trimCompanyName(companyName);
  const companyNameError = validateCompanyName(companyNameVal);
  if (!companyNameError) {
    trackCompanyCompleted(AccountDetailsEvents.companyNameCompleted());
  }
};

const trackWebsiteUrlCompleted = trackOnce();
export const setWebsite = (website) => ({ type: ACTIONS.SET_WEBSITE, website });
export const blurWebsite = (website) => () => {
  const websiteVal = trimWebsite(website);
  const websiteError = validateWebsite(websiteVal);
  if (!websiteError) {
    trackWebsiteUrlCompleted(AccountDetailsEvents.companyWebsiteCompleted());
  }
};

const trackEmailComplete = trackOnce();
export const setEmail = (email) => ({ type: ACTIONS.SET_EMAIL, email });
export const blurEmail = (email) => () => {
  const emailError = validateEmail(email).errorType;
  if (emailError === ERRORTYPES.NO_TYPE_SET) {
    trackEmailComplete('Profile email complete');
  }
};

export const setTitleHoneypot = (titleHoneypot) => ({
  type: ACTIONS.SET_TITLE_HONEYPOT,
  titleHoneypot
});

export const setIndustryHoneypot = (industryHoneypot) => ({
  type: ACTIONS.SET_INDUSTRY_HONEYPOT,
  industryHoneypot
});

export const toggleIsTransactional = () => ({
  type: ACTIONS.TOGGLE_IS_TRANSACTIONAL
});

export const toggleIsMarketing = () => ({ type: ACTIONS.TOGGLE_IS_MARKETING });

export const setCountryCode = (countryCode) => ({
  type: ACTIONS.SET_COUNTRY_CODE,
  countryCode
});

export const checkIsFormValid = () => ({ type: ACTIONS.CHECK_IS_FORM_VALID });

export const fetchStatus = () => (dispatch) =>
  fetch(createRequest('/v3/s2s/signup/status', { method: 'GET' })).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          if (isProfileComplete(json)) {
            redirectToMako();
            return;
          }
          dispatch({
            type: ACTIONS.USER_STATUS_FETCHED,
            status: json
          });
        });
      }
      if (response.status === 404) {
        redirectToMako();
      }
    }
  );

const addEase = () => fetch(createRequest('/v3/billing/message_storage'));

export const makoRedirect = async (response) => {
  const data = await response.json();

  if (data.setup_2fa_required === true) {
    setCookie(COOKIES.SETUP_2FA_REQUIRED, true, {
      secure: true
    });
    setCookie(COOKIES.SETUP_2FA_REQUIRED_CHECKPOINT, 'email', {
      secure: true
    });

    return window.location.replace(
      `${getEnvVariable(ENVIRONMENT.MAKO_HOST_URL)}/2fa_email_checkpoint`
    );
  } else if (showFreeTrialOnboarding()) {
    return window.location.replace(
      `${getEnvVariable(ENVIRONMENT.MAKO_HOST_URL)}/onboarding`
    );
  } else {
    return window.location.replace(
      `${getEnvVariable(
        ENVIRONMENT.MAKO_HOST_URL
      )}/guide?from=profile&integrate=true`
    );
  }
};

const saveResponse = async ({
  response,
  addOns,
  history,
  dispatch,
  profileInputs
}) => {
  const isAds = window.location.href.indexOf(accountTypes.ads) > -1;
  if (response.ok) {
    analytics.track(AccountDetailsEvents.accountProvisioned());

    // track event in Optimizely
    window.optimizely = window.optimizely || [];
    window.optimizely.push({
      type: 'event',
      eventName: 'Profile page complete'
    });

    if (hasIP(addOns)) {
      const ipAddon = addOns[ADDONS.DEDICATED_IP];
      analytics.track(AccountDetailsEvents.ipAdded(ipAddon));
    }
    if (hasEase(addOns)) {
      const easeReq = await addEase();
      if (easeReq.ok) {
        const easeAddon = addOns[ADDONS.EASE];
        analytics.track(AccountDetailsEvents.easeAdded(easeAddon));
      }
    }

    cleanUpSignUpSession();

    if (isAds) {
      window.location.replace(`${getEnvVariable(ENVIRONMENT.LABS_URL)}/grow`);
    } else {
      makoRedirect(response);
    }
  } else if (response.status === 403) {
    removeCookie(COOKIES.MAKO_AUTH);
    cleanUpSignUpSession();
    analytics.track(AccountDetailsEvents.accountBanned());
    history.push('/declined');
  } else {
    const { ERROR_MESSAGES, TAGS, TAG_VALUES, LEVELS } = TELEMETRY_ERROR_VALUES;
    dispatch({ type: ACTIONS.USER_PROFILE_FAILED });
    dispatch({
      type: APPINDICATORS.DISPLAY_ALERT,
      alertType: APPINDICATORS.ALERT_TYPES.DANGER,
      alertText: APPINDICATORS.ALERT_TEXT.GENERIC_ERROR
    });
    response
      .json()
      .then((data) => {
        telemetry.captureError(
          data.errors[0].message,
          TAGS.PROVISION,
          TAG_VALUES.PROVISION_FAILURE,
          LEVELS.FATAL
        );
      })
      .catch(() => {
        telemetry.captureError(
          ERROR_MESSAGES.NO_MESSAGE,
          TAGS.PROVISION,
          TAG_VALUES.PROVISION_FAILURE,
          LEVELS.FATAL
        );
      });
  }
  dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED });
};

export const onSave =
  (profileInputs, history) => async (dispatch, getState) => {
    const { addOns } = getState();
    dispatch({ type: APPINDICATORS.PAGE_LOADING });
    const phoneNumber =
      profileInputs.phoneNumber !== ''
        ? `+${profileInputs.countryCode}${profileInputs.phoneNumber}`
        : '';
    const isAds = window.location.href.indexOf(accountTypes.ads) > -1;
    const userProfile = {
      first_name: profileInputs.firstName,
      last_name: profileInputs.lastName,
      phone: phoneNumber,
      company: profileInputs.companyName,
      website: profileInputs.website
    };

    let userFields = {};
    const { titleHoneypot, industryHoneypot } = profileInputs;
    if (titleHoneypot || industryHoneypot) {
      userFields = {
        profile_information: {
          title: titleHoneypot,
          industry: industryHoneypot
        }
      };
    }

    const talon = getRawBrowserFingerprint();

    const payload = {
      talon,
      is_transactional: profileInputs.isTransactional || 0,
      is_marketing: profileInputs.isMarketing || 0,
      user_fields: userFields,
      user_profile: userProfile
    };

    // it's a reseller!
    if (profileInputs.email) {
      payload.email = profileInputs.email;
    }

    const token = getCookie(COOKIES.MAKO_AUTH);

    const options = {
      method: 'POST',
      headers: {
        Authorization: `token ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: { ...payload }
    };

    const response = await fetch(
      createRequest('/v3/s2s/signup/provision', options)
    );
    const saveProvisionInfo = {
      response,
      addOns,
      history,
      dispatch,
      profileInputs
    };
    await saveResponse(saveProvisionInfo).then();
  };

export const setAdsUser = (isAdsUser) => ({
  type: ACTIONS.SET_ADS_USER,
  isAdsUser
});

export const prefillUserProfileInputs = (dispatch, unifiedUser) => {
  return getUserProfile()
    .then((response) => {
      // By default set the unifiedUser
      if (unifiedUser) {
        const first_name = unifiedUser.firstName;
        const last_name = unifiedUser.lastName;
        first_name && dispatch(setFirstName(first_name));
        last_name && dispatch(setLastName(last_name));
      }
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to get user profile info');
    })
    .then((data) => {
      const { first_name, last_name, phone, company, website } = data;
      if (!unifiedUser) {
        first_name && dispatch(setFirstName(first_name));
        last_name && dispatch(setLastName(last_name));
      }
      phone && dispatch(setPhoneNumber(phone));
      company && dispatch(setCompanyName(company));
      website && dispatch(setWebsite(website));
    });
};

export const prefillUserEmailAddress = (dispatch) => {
  return getUserEmail()
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to get user email address');
    })
    .then((data) => {
      const { email } = data;
      email && dispatch(setEmail(email));
    });
};

export const prefillProfileInputs = (unifiedUser) => (dispatch) => {
  const userProfilePromise = prefillUserProfileInputs(dispatch, unifiedUser);
  const userEmailPromise = prefillUserEmailAddress(dispatch);
  return Promise.all([userProfilePromise, userEmailPromise]).catch((error) =>
    console.log(error)
  );
};
