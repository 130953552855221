import React from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';
import Styles from './header.module.scss';
import { calculateTotalPrice } from '../utils/displayHelpers';


const Header = (props) => {
  const totalPrice = calculateTotalPrice(props.activePackage, {});
  const shouldShowFreeText = (props.activePackage.length === 0 || totalPrice === 0);

  return (
    <header className={Styles['signup-header']} data-role="header-container">
      <div className={Styles['signup-top']}>
        <Logo />
      </div>
      {typeof props.title === 'string'
        ? (
          <h1 className="signup-title" data-role="header-title">
            {props.title}
          </h1>
        )
        : props.title
      }


      {typeof props.subtitle === 'string'
        ? (
          <p className="signupSubtitle" data-role="header-subtitle">
            {props.subtitle === 'Review your plan and create an account.' && shouldShowFreeText
              ? 'Sign up for free. No credit card required.'
              : props.subtitle
            }

          </p>
        )
        : (
          <p className="signupSubtitle" data-role="header-subtitle">
            {/* eslint-disable react/no-array-index-key */}
            {props.subtitle.map((s, i) => <span key={i}>{s}</span>)}
          </p>
        )
      }
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  activePackage: PropTypes.arrayOf(PropTypes.shape({
    adjusted_price: PropTypes.number,
    base_price: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })),
};

Header.defaultProps = {
  subtitle: '',
  activePackage: [],
};

export default Header;
