import * as Sentry from '@sentry/browser';

export const telemetry = {
  load() {
    const { SIGNUP_ENV, NODE_ENV } = process.env;

    return Sentry.init({
      dsn: 'https://eb8d22221e8e4256b841d3fcea5f299d@sentry.io/1522141',
      environment: SIGNUP_ENV,
      debug: false,
      beforeSend(event) {
        return NODE_ENV === 'development' ? null : event;
      },
    });
  },

  captureError(err, tag, value, warningLevel) {
    return Sentry.withScope((scope) => {
      scope.setTag(tag, value);
      scope.setLevel(warningLevel);
      Sentry.captureException(err);
    });
  },
};
