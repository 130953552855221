export default {
  AWS_ACCESS_KEY_ID: 'AWS_ACCESS_KEY_ID',
  AWS_SECRET_ACCESS_KEY: 'AWS_SECRET_ACCESS_KEY',
  DISTRIBUTION_ID: 'DISTRIBUTION_ID',
  MAKO_URL: 'MAKO_URL',
  CLOUDFRONT_URL: 'CLOUDFRONT_URL',
  RECAPTCHA_SITE_KEY: 'RECAPTCHA_SITE_KEY',
  RECAPTCHA_SITE_KEY_V3: 'RECAPTCHA_SITE_KEY_V3',
  SEGMENT_KEY: 'SEGMENT_KEY',
  LABS_URL: 'LABS_URL',
  ADS_PACKAGE_ID: 'ADS_PACKAGE_ID',
  MAKO_HOST_URL: 'MAKO_HOST_URL',
  SG_WEBSITE_URL: 'SG_WEBSITE_URL',
  GOOGLE_RESELLER: 'GOOGLE_RESELLER',
  GOOGLE_RESELLER_CREATE_URL: 'GOOGLE_RESELLER_CREATE_URL',
  AUTH0_AUDIENCE: 'AUTH0_AUDIENCE',
  AUTH0_CLIENT_ID: 'AUTH0_CLIENT_ID',
  AUTH0_DOMAIN: 'AUTH0_DOMAIN',
  TWILIO_HOST: 'TWILIO_HOST',
  SEGMENT_HOST: 'SEGMENT_HOST',
  LOGIN_HOST_URL: 'LOGIN_HOST_URL',
  TWILIO_CONSOLE_URL: 'TWILIO_CONSOLE_URL',
  SHOW_FREE_TRIAL_ONBOARDING: 'SHOW_FREE_TRIAL_ONBOARDING',
  DATADOG_ANALYTICS_ENABLED: 'DATADOG_ANALYTICS_ENABLED',
  DATADOG_APPLICATION_ID: 'DATADOG_APPLICATION_ID',
  DATADOG_CLIENT_TOKEN: 'DATADOG_CLIENT_TOKEN',
};
