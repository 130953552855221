import {
  Page, Section,
} from './constants';

export const BillingEvents = {
  loadPaymentFormFailed: () => ({
    event: 'load payment form failed',
    properties: {
      page: Page.billing,
      section: Section.paymentForm,
    },
  }),
  billingCompleted: () => ({
    event: 'billing completed',
    properties: {
      page: Page.billing,
      section: Section.paymentForm,
    },
  }),
};
