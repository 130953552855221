// declare these as constants in /constants/ later
import ERRORTYPES from '../constants/errorTypes';
import validationRules from '../constants/validationRules';

/* //
    HELPER FUNCTIONS
 */

// validates length based on min max length (not inclusive)
function validateLength(min, max, value) {
  return typeof value === 'string' && value.length > min && value.length < max;
}

function checkValidEmail(email) {
  // General Email Regex (RFC 5322 Official Standard) from http://emailregex.com/
  const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmail.test(email);
}

function checkHasLetter(value) {
  const hasLetter = new RegExp('^(?=.*[a-zA-Z])');
  return hasLetter.test(value);
}

function checkHasDigit(value) {
  const hasDigit = new RegExp('^(?=.*[0-9])');
  return hasDigit.test(value);
}

function checkIsAscii(value) {
  return !/[^\x00-\x7F]+/.test(value);
}

function assembleUsernameValidationErrorString(validationArr) {
  return `${ERRORTYPES.USERNAME_PREFIX}${validationArr.join(' and')}.`;
}

function assemblePasswordValidationErrorString(validationArr) {
  if (validationArr.length && validationArr[0] === ERRORTYPES.PASSWORD_LONG) {
    return `${ERRORTYPES.PASSWORD_PREFIX_FLEXIBLE}${validationArr.join(' and')}.`;
  }
  return `${ERRORTYPES.PASSWORD_PREFIX}${validationArr.join(' and')}.`;
}

export function checkMatching(val1, val2) {
  return val2 !== undefined && val1 !== undefined && val1 === val2;
}

// validates email and generates dynamic error message if invalid
export function validateEmail(email) {
  const response = {
    isValid: false,
    errorType: ERRORTYPES.NO_TYPE_SET,
  };

  if (email === '') {
    response.errorType = ERRORTYPES.EMAIL_DEFAULT;
    return response;
  }

  response.isValid = validateLength(validationRules.EMAIL_LENGTH_MIN, validationRules.EMAIL_LENGTH_MAX, email)
    && checkValidEmail(email);

  if (!response.isValid) {
    response.errorType = ERRORTYPES.EMAIL_INVALID;
    if (email.length <= validationRules.EMAIL_LENGTH_MIN) {
      response.errorType = ERRORTYPES.EMAIL_SHORT;
    } else if (email.length >= validationRules.EMAIL_LENGTH_MAX) {
      response.errorType = ERRORTYPES.EMAIL_LONG;
    }
  }

  return response;
}

// validates username and generates dynamic error message if invalid
export function validateUsername(username, canBeEmail = false) {
  const response = {
    isValid: false,
    errorType: ERRORTYPES.NO_TYPE_SET,
  };
  if (username === undefined || username === '') {
    response.errorType = `${ERRORTYPES.USERNAME_PREFIX}${ERRORTYPES.USERNAME_DEFAULT}`;
    return response;
  }
  const isSendGridDomain = ((username.toString()).match(/\@sendgrid\.+[a-zA-Z]{2,}$/));
  const hasSpace = /\s/g.test(username);
  const isValidEmailAddress = checkValidEmail(username);

  response.isValid = validateLength(
    validationRules.USERNAME_LENGTH_MIN,
    validationRules.USERNAME_LENGTH_MAX,
    username,
  )
    && checkHasLetter(username)
    && checkIsAscii(username)
    && !isSendGridDomain
    && !hasSpace
    && (canBeEmail ? true : !isValidEmailAddress);
  if (!response.isValid) {
    const validationMsgArr = [];

    if (username.length <= validationRules.USERNAME_LENGTH_MIN) {
      validationMsgArr.push(ERRORTYPES.USERNAME_SHORT);
    }
    if (username.length >= validationRules.USERNAME_LENGTH_MAX) {
      validationMsgArr.push(ERRORTYPES.USERNAME_LONG);
    }
    if (!checkHasLetter(username)) {
      validationMsgArr.push(ERRORTYPES.USERNAME_NO_LETTER);
    }
    if (!checkIsAscii(username)) {
      validationMsgArr.push(ERRORTYPES.USERNAME_INVALID_CHARACTERS);
    }
    if (isSendGridDomain) {
      validationMsgArr.push(ERRORTYPES.USERNAME_SENDGRID_DOMAIN);
    }
    if (hasSpace) {
      validationMsgArr.push(ERRORTYPES.USERNAME_SPACE);
    }
    if (!canBeEmail && isValidEmailAddress && !isSendGridDomain) {
      validationMsgArr.push(ERRORTYPES.USERNAME_EMAIL_ADDRESS);
    }
    if (validationMsgArr.length === 0) {
      validationMsgArr.push(ERRORTYPES.USERNAME_DEFAULT);
    }

    response.errorType = assembleUsernameValidationErrorString(validationMsgArr);
  }
  return (response);
}

// validates confirm password and generates error message if invalid
export function validateConfirmPassword(confirmPassword, password) {
  const response = {
    isValid: false,
    errorType: ERRORTYPES.NO_TYPE_SET,
  };

  if (confirmPassword === '') {
    response.errorType = ERRORTYPES.PASSWORD_CONFIRM;
    return response;
  }

  response.isValid = confirmPassword === password;

  if (!response.isValid) {
    response.errorType = ERRORTYPES.PASSWORD_NO_MATCH;
  }

  return response;
}

export function getPasswordLengthErrors(password) {
  const errorMessages = [];
  if (password.length < validationRules.PASSWORD_LENGTH_MIN) {
    errorMessages.push(ERRORTYPES.PASSWORD_SHORT);
  } if (password.length >= validationRules.PASSWORD_LENGTH_MAX) {
    errorMessages.push(ERRORTYPES.PASSWORD_LONG);
  }
  return errorMessages;
}

// validates password and generates error message if invalid
export function validatePassword(password, confirmPassword, username, shouldValidateOnlyLength = false) {
  const response = {
    isValid: false,
    errorType: ERRORTYPES.NO_TYPE_SET,
    confirmPassword_isValid: false,
    confirmPassword_errorType: ERRORTYPES.NO_TYPE_SET,
  };

  if (!password || password === '') {
    response.errorType = `${ERRORTYPES.PASSWORD_PREFIX}${ERRORTYPES.PASSWORD_SHORT}`;
    return response;
  }

  const validationMsgArr = [...getPasswordLengthErrors(password)];

  if (!shouldValidateOnlyLength) {
    const usernameAndPasswordMatch = checkMatching(username, password);

    if (!checkHasDigit(password)) {
      validationMsgArr.push(ERRORTYPES.PASSWORD_NO_DIGIT);
    } if (!checkHasLetter(password)) {
      validationMsgArr.push(ERRORTYPES.PASSWORD_NO_LETTER);
    } if (!checkIsAscii(password)) {
      validationMsgArr.push(ERRORTYPES.PASSWORD_NON_ASCII);
    } if (usernameAndPasswordMatch) {
      validationMsgArr.push(ERRORTYPES.PASSWORD_MATCH_USERNAME);
    }

    const confirmPasswordCheck = validateConfirmPassword(confirmPassword, password);
    response.confirmPassword_errorType = confirmPasswordCheck.errorType;
    response.confirmPassword_isValid = confirmPasswordCheck.isValid;
  }

  if (!validationMsgArr.length) {
    response.isValid = true;
    return response;
  }

  response.errorType = assemblePasswordValidationErrorString(validationMsgArr);

  return response;
}
