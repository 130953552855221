import {
  Page, Section, ElementType, EventType,
} from './constants';

export const SignupDeclinedEvents = {
  contactSupportClicked: () => ({
    event: 'contact support clicked',
    properties: {
      page: Page.declined,
      section: Section.provideFurtherInfo,
      elementType: ElementType.button,
      eventType: EventType.click,
    },
  }),
};
