import { connect } from 'react-redux';
import { setPackage, getPackages } from '../actions';

const mapDispatchToProps = dispatch => ({
  setPackage: (activePackage) => {
    dispatch(setPackage(activePackage));
  },
  getPackages: () => {
    dispatch(getPackages());
  },
});

function mapStateToProps({ packages }) {
  return { packages };
}

export default ComposedCmp => (connect(mapStateToProps, mapDispatchToProps)(ComposedCmp));
