import React from 'react';
import Styles from './summarycard.module.scss';
import AdSummaryStyles from './adsSummaryCard.module.scss';
import BetaBadge from './BetaBadge';

const AdsSummaryCard = () => (
  <div className={Styles.summarycard}>
    <div className={AdSummaryStyles.adsInfoContainer}>
      <h2 className={AdSummaryStyles.adsInfoHeading}>
SendGrid Ads
        <BetaBadge />
      </h2>
      <p className={AdSummaryStyles.adsInfo}>
Sign up for SendGrid Ads
        <span className={AdSummaryStyles.bolded}> for free</span>
.
         Pay only once you publish an ad campaign.
      </p>
      <a href="https://help.labs.sendgrid.com/sendgrid-ads/sendgrid-ads-pricing" rel="noopener noreferrer" target="_blank">How much does Ads cost?</a>
    </div>
  </div>
);

export default AdsSummaryCard;
