import React, { useEffect, useState } from 'react';
import { analytics } from '@sendgrid/bloodhound';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/**
 * The purpose of this component is to detect when a route changes in a React SPA
 * - if you need to hook into a route change, please see useEffect below
 */
const RouteChangeDetector = (props) => {
  const [currentPath, setCurrentPath] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const affiliate = queryParams.get('affiliate');
  useEffect(() => {
    // use currentPath as referrer before updating its value to the new path
    analytics.page({
      properties: {
        referrer: currentPath,
        affiliate,
      },
    });

    setCurrentPath(props.location.pathname);
  }, [props.location.pathname]);

  return <>{props.children}</>;
};

RouteChangeDetector.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default withRouter(RouteChangeDetector);
