import React from 'react';
import { Button } from '@sendgrid/ui-components/button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PersonalInfo from './PersonalInfo';
import Honeypot from './Honeypot';
import withProfileInputs from '../containers/withProfileInputs';
import withPackages from '../containers/withPackages';
import {
  getEmailApiPlan,
  getMarketingCampaignsPlan
} from '../utils/packageHelper';
import Styles from './profileForm.module.scss';
import { WriteSelectors } from './profileForm-qa-hooks';

export class ProfileForm extends React.Component {
  componentDidMount() {
    this.props.setPageLoading();
    const statusPromise = this.props.fetchStatus();
    const profileInputsPromise = this.props.prefillProfileInputs(this.props.unifiedUser);
    Promise.all([statusPromise, profileInputsPromise]).then(() =>
      this.props.setPageLoadingFinished()
    );
    this.dispatchEmailMethod(this.props.packages);
  }

  dispatchEmailMethod(packages) {
    if (
      !packages ||
      !packages.activePackage ||
      packages.activePackage.length === 0
    ) {
      return;
    }
    const selectedMCPlan = getMarketingCampaignsPlan(packages.activePackage);
    const selectedEIPlan = getEmailApiPlan(packages.activePackage);

    // Since every user defaults to free for 30 days its hard to know intent any more
    // this was once based on what type of paid package a user selected while signing up
    if (
      selectedEIPlan &&
      selectedEIPlan.id === 'sg.ei.free-100.v1' &&
      selectedMCPlan &&
      selectedMCPlan.id === 'sg.mc.free.v1'
    ) {
      return;
    }

    if (selectedEIPlan) {
      this.props.toggleIsTransactional();
    }
    if (selectedMCPlan) {
      this.props.toggleIsMarketing();
    }
  }

  render() {
    return (
      <div className={Styles.profileForm}>
        <PersonalInfo unifiedUser={this.props.unifiedUser} />
        <Honeypot />
        <div className={Styles.submitButton}>
          <Button
            type="primary"
            onClick={() =>
              this.props.onSave(this.props.profileInputs, this.props.history)
            }
            data-role="save-button"
            disabled={!this.props.profileInputs.isValidForm}
            {...WriteSelectors.getStartedButton}
          >
            Get Started!
          </Button>
        </div>
      </div>
    );
  }
}

ProfileForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  toggleIsTransactional: PropTypes.func.isRequired,
  toggleIsMarketing: PropTypes.func.isRequired,
  profileInputs: PropTypes.shape({
    isValidForm: PropTypes.bool.isRequired
  }).isRequired,
  fetchStatus: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  packages: PropTypes.shape({
    activePackage: PropTypes.array.isRequired,
    activeIsFree: PropTypes.bool.isRequired,
    packagesLoaded: PropTypes.bool.isRequired
  }).isRequired,
  unifiedUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })
};

export default withRouter(withPackages(withProfileInputs(ProfileForm)));
