const selectQaHook = (hook: string): string => `[data-qahook="${hook}"]`;
const writeQaHook = (hook: string): WriteQaHook => ({ 'data-qahook': hook });

export interface WriteQaHook {
  'data-qahook': string;
}

type WriteSelectors<T> = { [P in keyof T]: WriteQaHook };
type Selectors<T> = { [P in keyof T]: string };

export const writeSelectorGenerator = <T extends Record<string, string | null>>(
  selectors: T
) =>
  // Adds objects with data-qahooks properties intended to be passed/spread into React component props
  // i.e. { key: { data-qahook: <some_qa_hook> }, ... }
  // Usage:
  // <ReactComponent {...WriteSelectors.buttonSelector} /> spreads the buttonSelector's 'data-qahook' object selector onto your component
  Object.keys(selectors)
    .map((key) => {
      return {
        [key]:
          selectors[key] === null
            ? // When null, we assume the selector key and data-qahook will have the same name
              // i.e. { someSelector: null } => { someSelector: { 'data-qahook': 'someSelector' } }
              writeQaHook(key)
            : // When selectors[key] has some string value, we assume the intent is to override the name of the data-qahook attribute value
              // i.e. { someSelector: 'otherName' } => { someSelector: { 'data-qahook': 'otherName' } }
              writeQaHook(selectors[key] as string),
      };
    })
    .reduce((accumulator, nextValue) => ({
      ...accumulator,
      ...nextValue,
    })) as WriteSelectors<T>;

export const selectSelectorGenerator = <
  T extends Record<string, string | null>
>(
  selectors: T
) =>
  // CSS selector version of the the data-qahooks attributes intended to be used in Cypress page objects and unit tests
  // i.e. { key: '[data-qahook="<some_qa_hook>"]', ... }
  // Usage:
  // cy.get(Selectors.addButton) in Cypress page objects/specs
  // wrapper.find(Selectors.addButton) in Jest/Enzyme unit tests
  Object.keys(selectors)
    .map((key) => ({
      [key]:
        selectors[key] === null
          ? // When null, we assume the selector key and data-qahook will have the same name
            // i.e. { someSelector: null } => { someSelector: '[data-qahook="someSelector"]' }
            selectQaHook(key)
          : // When selectors[key] has some string value, we assume the intent is to override the name of the data-qahook attribute
            // i.e. { someSelector: 'otherName' } => { someSelector: '[data-qahook="otherName"]' }
            selectQaHook(selectors[key] as string),
    }))
    .reduce((accumulator, nextValue) => ({
      ...accumulator,
      ...nextValue,
    })) as Selectors<T>;
