import queryString from 'query-string';
import moment from 'moment';
import { ACTIONS, COOKIES, APPINDICATORS } from '../constants';
import { getParsedCookie, removeCookie } from '../utils/cookieHelpers';
import packageConfig from '../utils/packageConfig.js';
import {
  mapEmailApiPlan,
  mapMarketingCampaignsPlan,
} from '../adapters/offeringsAdapter';
import createRequest from '../utils/requestService';

export const setPackage = activePackage => ({ type: ACTIONS.SET_PACKAGE, activePackage });
export const getPackagesSuccess = payload => ({ type: ACTIONS.GET_PACKAGES_SUCCESS, payload });

function getOfferingById(offeringId) {
  return fetch(createRequest(`/v3/public/offerings/${offeringId}`, { method: 'GET' }))
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }
      throw new Error(`Failed to load OES offering ${offeringId}`);
    });
}

export function isOfferingActive(offering) {
  if (!offering) {
    return false;
  }
  if (!offering.start_date) {
    return true;
  }
  const now = moment().utc();
  const startDate = moment(offering.start_date).utc();
  return startDate <= now;
}

export const isSignupEligible = (plan) => {
  if (!plan || !plan.entitlements) {
    return true;
  }

  return plan.entitlements.is_signup_eligible;
};

export const getPackages = () => (dispatch) => {
  // Example AWS sign up query string:
  // ?cuuid=cde7778b-01b0-45f0-9495-b1208dee90a7&aws=pro
  const parsedQS = queryString.parse(window.location.search);
  if (parsedQS.aws) {
    removeCookie(COOKIES.SELECTED_PACKAGES);
    const { aws, cuuid } = parsedQS;
    const awsPackage = packageConfig.awsPackages[aws];
    if (!awsPackage) {
      dispatch({ type: ACTIONS.GET_PACKAGES_FAILURE });
      return Promise.resolve();
    }
    awsPackage.cuuid = cuuid;
    dispatch(setPackage([awsPackage]));
    dispatch({ type: ACTIONS.GET_PACKAGES_SUCCESS });
    return Promise.resolve();
  }

  const signUpVals = getParsedCookie(COOKIES.SELECTED_PACKAGES);
  if (!signUpVals || !signUpVals.packages) {
    dispatch({ type: ACTIONS.GET_PACKAGES_FAILURE });
    return Promise.resolve();
  }

  dispatch({ type: APPINDICATORS.PAGE_LOADING });

  const eiPlanId = signUpVals.packages.e;
  const mcPlanId = signUpVals.packages.mc;

  const intendedEIPlanPromise = eiPlanId ? getOfferingById(eiPlanId) : Promise.resolve();
  const intendedMCPlanPromise = mcPlanId ? getOfferingById(mcPlanId) : Promise.resolve();

  return Promise.all([
    intendedEIPlanPromise,
    intendedMCPlanPromise,
  ])
    .then(([
      intendedEIPlan,
      intendedMCPlan,
    ]) => {
      const activePackage = [];
      const isEIPlanSignupEligible = isSignupEligible(intendedEIPlan);
      const isMCPlanSignupEligible = isSignupEligible(intendedMCPlan);
      if (isOfferingActive(intendedEIPlan) && isEIPlanSignupEligible) {
        activePackage.push(mapEmailApiPlan(intendedEIPlan));
      }
      if (isOfferingActive(intendedMCPlan) && isMCPlanSignupEligible) {
        activePackage.push(mapMarketingCampaignsPlan(intendedMCPlan));
      }
      dispatch(setPackage(activePackage));

      dispatch(getPackagesSuccess({
        intendedEIPlan,
        intendedMCPlan,
      }));
    })
    .catch(() => {
      dispatch({ type: ACTIONS.GET_PACKAGES_FAILURE });
    })
    .finally(() => {
      dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED });
    });
};
