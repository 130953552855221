import React from 'react';
import { Navbar } from './Navbar';
import { useAuth0 } from '@auth0/auth0-react';

export const NavbarContainer = () => {
  const { isAuthenticated, user } = useAuth0();
  return (
    isAuthenticated && (
      <Navbar
        name={`${user.given_name} ${user.family_name}`}
        email={user.email}
      />
    )
  );
};
