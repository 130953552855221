import packageConfig from './packageConfig.js';

export const dollarize = num => (
  num ? `$${parseFloat(num).toFixed(2)}` : '$0.00'
);

export const dollarizeNoDecimal = num => (
  num ? `$${parseFloat(num).toFixed(0)}` : '$0'
);

export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatIpString = num => `${num} additional IP${num !== 1 ? 's' : ' '}`;

export const formatEaseString = num => `${num} days of Email Activity History`;

export const formatEaseOptionString = num => `${num} days of History`;

export function isPackageAws(activePackage) {
  return activePackage && activePackage.some(pkg => pkg.is_aws);
}

export function isPackageAds(activePackage) {
  return activePackage && activePackage.some(pkg => pkg.id in packageConfig.adsPackages);
}

export function isPackagePro(activePackage) {
  if (activePackage in packageConfig.proPackages) {
    return true;
  }
  return false;
}

// calculate package price based on date of the month
export const calculateEstimatedProratedPrice = (price) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentYear = currentDate.getYear();
  const currentMonth = currentDate.getMonth();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const remainingMonth = (daysInMonth - currentDay + 1) / daysInMonth;
  // the percentage of month left times the price
  return remainingMonth * price;
};

// return sum of all objects.adjusted_price in array plus addons totals
const addOnDefault = { ip: 0, ease: 3 };
export const calculateTotalPrice = (products = [], addOns = addOnDefault) => {
  let totalPrice = 0;
  if (addOns.ip > 0 && addOns.ipPrice > 0) {
    totalPrice += (addOns.ip * addOns.ipPrice);
  }

  if (addOns.ease > 3 && addOns.easePrice > 0) {
    totalPrice += addOns.easePrice;
  }

  totalPrice += products.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.adjusted_price ? currentValue.adjusted_price : 0),
    0,
  );

  return totalPrice;
};

export function isPackageFree(activePackage) {
  return calculateTotalPrice(activePackage) === 0;
}

export function isPaymentRequired(activePackage) {
  return activePackage
    && activePackage.length
    && !isPackageAws(activePackage)
    && !isPackageFree(activePackage);
}
