import { analytics } from '@sendgrid/bloodhound';
import {
  ACTIONS, COOKIES, APPINDICATORS, ADDONS, TELEMETRY_ERROR_VALUES,
} from '../constants';
import createRequest from '../utils/requestService';
import { isPackageAds } from '../utils/displayHelpers';
import { getCookie } from '../utils/cookieHelpers';
import { trackOnce } from '../utils/Analytics';
import { telemetry } from '../utils/telemetry';
import { BillingEvents } from '../analytics/billingEvents';

const trackBillingSectionComplete = trackOnce();

const sendPaymentConfirmation = (paymentMethodId, activePackageId, ipCount, easeCount) => {
  const body = {
    signup_session_token: getCookie(COOKIES.SIGNUP_SESSION),
    package_id: activePackageId,
    payment_method_id: paymentMethodId,
    additional_ip_count: ipCount,
  };
  if (easeCount > 3) {
    body.ease_days = easeCount;
  }

  return fetch(createRequest(
    '/v3/public/s2s/signup/payment_confirmation',
    { body },
  ));
};

const createBossAccount = () => {
  const body = {
    signup_session_token: getCookie(COOKIES.SIGNUP_SESSION),
    first_name: 'SendGrid',
    last_name: 'User',
  };

  return fetch(createRequest(
    '/v3/public/billing/accounts',
    { body },
  ));
};

export const getPaymentForm = history => async (dispatch, getState) => {
  dispatch({ type: APPINDICATORS.PAGE_LOADING });

  let zuoraPaymentFormParams;
  let prepopulateFields;
  try {
    const req = await fetch(createRequest(
      '/v3/public/billing/payment_form?is_signup=1',
      { method: 'GET' }
    ));
    const paymentInfo = await req.json();
    zuoraPaymentFormParams = {
      tenantId: paymentInfo.result.tenant_id,
      id: paymentInfo.result.page_id,
      token: paymentInfo.result.token,
      signature: paymentInfo.result.signature,
      key: paymentInfo.result.key,
      url: paymentInfo.result.url,
      style: 'inline',
      submitEnabled: true,
    };
    prepopulateFields = {
      field_email: getState().formInputs.email_value,
    };
  } catch (e) {
    const {
      TAGS, LEVELS, TAG_VALUES, ERROR_MESSAGES,
    } = TELEMETRY_ERROR_VALUES;
    telemetry.captureError(
      ERROR_MESSAGES.ZUORA_PAYMENT_FORM,
      TAGS.ZUORA,
      TAG_VALUES.PAYMENT_FORM_PARAMETERS,
      LEVELS.FATAL,
    );
    // eslint-disable-next-line
    console.error(`Something went wrong with requesting payment form: ${e}`);
    dispatch({
      type: APPINDICATORS.DISPLAY_ALERT,
      alertType: APPINDICATORS.ALERT_TYPES.DANGER,
      alertText: APPINDICATORS.ALERT_TEXT.BILLING_DOWN,
    });
    history.push('/account_details');
    dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED });
  }

  if (zuoraPaymentFormParams && prepopulateFields) {
    dispatch({
      type: ACTIONS.GET_PAYMENT_FORM,
      formParams: zuoraPaymentFormParams,
      prepopulateFields,
      dataLoaded: true,
    });
  }

  dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED });
};

export const zuoraCallback = (response, history) => async (dispatch, getState) => {
  const { addOns, packages } = getState();
  const { activePackage, comboPackageId } = packages;
  const {
    TAGS, TAG_VALUES, LEVELS, ERROR_MESSAGES,
  } = TELEMETRY_ERROR_VALUES;
  let billingFailed = true;

  dispatch({ type: APPINDICATORS.PAGE_LOADING });

  if (response.success === 'false' || !response.success) {
    analytics.track(BillingEvents.loadPaymentFormFailed());
    telemetry.captureError(ERROR_MESSAGES.ZUORA_FORM_ERROR, TAGS.ZUORA, TAG_VALUES.FORM_ERROR, LEVELS.FATAL);
  } else {
    try {
      // create acct in boss
      const bossReq = await createBossAccount(); // session token passed
      if (bossReq.ok && activePackage.length) {
        // confirm payment with ip count addon

        const sendPaymentConfReq = await sendPaymentConfirmation(
          response.refId,
          comboPackageId,
          addOns[ADDONS.DEDICATED_IP],
          addOns[ADDONS.EASE],
        );
        if (sendPaymentConfReq.ok) {
          billingFailed = false;
          dispatch({
            type: APPINDICATORS.DISPLAY_ALERT,
            alertType: APPINDICATORS.ALERT_TYPES.SUCCESS,
            alertText: APPINDICATORS.ALERT_TEXT.USER_CREATION_SUCCESS,
          });
        }
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(`Something went wrong with zuora callback requests: ${e}`);
      telemetry.captureError(e, TAGS.ZUORA, TAG_VALUES.BOSS_PAYMENT_ERROR, LEVELS.FATAL);
    }
  }

  // We let the user continue to the next step even if we can't
  // capture payment. We still consider the billing section
  // complete from a tracking perspective in that case.
  trackBillingSectionComplete(BillingEvents.billingCompleted());

  dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED });

  if (billingFailed) {
    dispatch({
      type: APPINDICATORS.DISPLAY_ALERT,
      alertType: APPINDICATORS.ALERT_TYPES.DANGER,
      alertText: APPINDICATORS.ALERT_TEXT.BILLING_DOWN,
    });
  }

  if (isPackageAds(activePackage)) {
    history.push('/ads/account_details');
  } else {
    history.push('/account_details');
  }
};

export const logPaymentProviderError = message => async () => {
  const { TAGS, TAG_VALUES, LEVELS } = TELEMETRY_ERROR_VALUES;
  telemetry.captureError(message, TAGS.ZUORA, TAG_VALUES.PAYMENT_ERROR, LEVELS.INFO);
};
