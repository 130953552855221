export default {
  PAGE_LOADING: 'pageLoading',
  PAGE_LOADING_FINISHED: 'pageNotLoading',
  DISPLAY_ALERT: 'displayAlert',
  HIDE_ALERT: 'hideAlert',
  ALERT_TYPES: {
    DANGER: 'danger',
    WARNING: 'warning',
    SUCCESS: 'success',
  },
  ALERT_TEXT: {
    BILLING_DOWN: 'Our billing provider is currently unavailable. Sorry about that! Use this free account and upgrade within your Settings when our provider is back online.',
    IP_DOWN: 'Your account was created, but we weren\'t able to process your additional IP(s) purchase. Sorry about that! Sign in and buy additional IPs within your Settings.',
    USER_CREATION_ERROR: 'We were unable to create your account. Sorry about that! Please try again later.',
    GENERIC_ERROR: 'We were unable to save your information. Sorry about that! Please try again later.',
    USER_CREATION_SUCCESS: 'Your account has been created. Welcome to Twilio SendGrid—we\'re happy to have you!  🎉',
    CREATE_USER_ERROR_PASSWORD: 'Please update your password to meet the security requirements.',
  },
};
