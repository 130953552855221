import { APPINDICATORS } from '../constants';

const INITIAL_STATE = {
  isPageLoading: false,
  alertActive: false,
  alertText: '',
  alertType: '',
};

const appIndicators = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPINDICATORS.PAGE_LOADING:
      return {
        ...state,
        isPageLoading: true,
      };
    case APPINDICATORS.PAGE_LOADING_FINISHED:
      return {
        ...state,
        isPageLoading: false,
      };
    case APPINDICATORS.DISPLAY_ALERT:
      return {
        ...state,
        alertActive: true,
        alertType: action.alertType,
        alertText: action.alertText,
      };
    case APPINDICATORS.HIDE_ALERT:
      return {
        ...state,
        alertActive: false,
        alertType: '',
        alertText: '',
      };
    default:
      return state;
  }
};

export default appIndicators;
