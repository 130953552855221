import { connect } from 'react-redux';
import {
  setFirstName,
  blurFirstName,
  setLastName,
  blurLastName,
  setPhoneNumber,
  blurPhoneNumber,
  setCompanyName,
  blurCompanyName,
  setWebsite,
  blurWebsite,
  setEmail,
  blurEmail,
  setTitleHoneypot,
  setIndustryHoneypot,
  toggleIsTransactional,
  toggleIsMarketing,
  checkIsFormValid,
  onSave,
  fetchStatus,
  setAdsUser,
  setCountryCode,
  prefillProfileInputs
} from '../actions';
import { APPINDICATORS } from '../constants';

const mapDispatchToProps = dispatch => ({
  setFirstName: (e, firstName) => {
    dispatch(setFirstName(firstName));
    dispatch(checkIsFormValid());
  },
  blurFirstName: (e, firstName) => {
    dispatch(blurFirstName(firstName));
  },
  setLastName: (e, lastName) => {
    dispatch(setLastName(lastName));
    dispatch(checkIsFormValid());
  },
  blurLastName: (e, lastName) => {
    dispatch(blurLastName(lastName));
  },
  setPhoneNumber: (e, phoneNumber) => {
    dispatch(setPhoneNumber(phoneNumber));
    dispatch(checkIsFormValid());
  },
  blurPhoneNumber: (e, phoneNumber) => {
    dispatch(blurPhoneNumber(phoneNumber));
  },
  setCompanyName: (e, companyName) => {
    dispatch(setCompanyName(companyName));
    dispatch(checkIsFormValid());
  },
  blurCompanyName: (e, companyName) => {
    dispatch(blurCompanyName(companyName));
  },
  setWebsite: (e, website) => {
    dispatch(setWebsite(website));
    dispatch(checkIsFormValid());
  },
  blurWebsite: (e, website) => {
    dispatch(blurWebsite(website));
  },
  setEmail: (e, email) => {
    dispatch(setEmail(email));
    dispatch(checkIsFormValid());
  },
  blurEmail: (e, email) => {
    dispatch(blurEmail(email));
  },
  setTitleHoneypot: (event) => {
    dispatch(setTitleHoneypot(event.target.value));
  },
  setIndustryHoneypot: (event) => {
    dispatch(setIndustryHoneypot(event.target.value));
  },
  toggleIsTransactional: () => {
    dispatch(toggleIsTransactional());
    dispatch(checkIsFormValid());
  },
  toggleIsMarketing: () => {
    dispatch(toggleIsMarketing());
    dispatch(checkIsFormValid());
  },
  onSave: (profileInputs, history) => {
    dispatch(onSave(profileInputs, history));
  },
  fetchStatus: () => dispatch(fetchStatus()),
  setAdsUser: (isAdsUser) => {
    dispatch(setAdsUser(isAdsUser));
  },
  setCountryCode: (event) => {
    dispatch(setCountryCode(event.value));
  },
  prefillProfileInputs: (unifiedUser) => dispatch(prefillProfileInputs(unifiedUser)),
  setPageLoading: () => dispatch({ type: APPINDICATORS.PAGE_LOADING }),
  setPageLoadingFinished: () =>
    dispatch({ type: APPINDICATORS.PAGE_LOADING_FINISHED })
});

function mapStateToProps({ profileInputs }) {
  return { profileInputs };
}

export default (ComposedCmp) =>
  connect(mapStateToProps, mapDispatchToProps)(ComposedCmp);
