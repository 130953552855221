import { ACTIONS, ERRORTYPES } from '../constants';
import {
  validateFirstName,
  validateLastName,
  validPhoneNumber,
  validatePhoneNumber,
  validateWebsite,
  validateCompanyName,
  trimCompanyName,
  trimWebsite
} from '../utils/profileInput';
import { validateEmail } from '../utils/formInput';

const INITIAL_STATE = {
  firstName: '',
  firstNameError: null,
  lastName: '',
  lastNameError: null,
  phoneNumber: '',
  phoneNumberError: null,
  companyName: '',
  companyNameError: null,
  website: '',
  websiteError: null,
  email: '',
  emailError: null,
  titleHoneypot: '',
  industryHoneypot: '',
  countryCode: '',
  isTransactional: 0,
  isMarketing: 0,
  isValidForm: false,
  isReseller: false,
  isAds: false
};

const validateFields = (...fields) => {
  const noError = (field) => field === null;
  return fields.every(noError);
};

const checkRequiredFields = (...fields) => {
  const isNotEmpty = (field) => field !== '';
  return fields.every(isNotEmpty);
};

const profileInputs = (state = INITIAL_STATE, action) => {
  let isValidForm;
  if (state.isAds) {
    isValidForm =
      checkRequiredFields(
        state.firstName,
        state.lastName,
        state.companyName,
        state.website,
        state.phoneNumber
      ) &&
      validateFields(
        state.firstNameError,
        state.lastNameError,
        state.phoneNumberError,
        state.companyNameError,
        state.websiteError
      );
  } else {
    isValidForm =
      checkRequiredFields(
        state.firstName,
        state.lastName,
        state.companyName,
        state.website,
        state.phoneNumber
      ) &&
      validateFields(
        state.firstNameError,
        state.lastNameError,
        state.phoneNumberError,
        state.companyNameError,
        state.websiteError
      );
  }
  if (state.isReseller && isValidForm) {
    isValidForm =
      checkRequiredFields(state.email) && validateFields(state.emailError);
  }

  switch (action.type) {
    case ACTIONS.SET_FIRST_NAME: {
      const firstNameError = validateFirstName(action.firstName);
      return {
        ...state,
        firstName: action.firstName,
        firstNameError
      };
    }
    case ACTIONS.SET_LAST_NAME: {
      const lastNameError = validateLastName(action.lastName);
      return {
        ...state,
        lastName: action.lastName,
        lastNameError
      };
    }
    case ACTIONS.SET_PHONE_NUMBER: {
      const phoneNumber = validPhoneNumber(action.phoneNumber);
      const phoneNumberError = validatePhoneNumber(phoneNumber);
      return {
        ...state,
        phoneNumber,
        phoneNumberError
      };
    }
    case ACTIONS.SET_COMPANY_NAME: {
      const companyName = trimCompanyName(action.companyName);
      const companyNameError = validateCompanyName(companyName);
      return {
        ...state,
        companyName,
        companyNameError
      };
    }
    case ACTIONS.SET_WEBSITE: {
      const website = trimWebsite(action.website);
      const websiteError = validateWebsite(website);
      return {
        ...state,
        website,
        websiteError
      };
    }
    case ACTIONS.SET_EMAIL: {
      const emailErrorType = validateEmail(action.email).errorType;
      const emailError =
        emailErrorType === ERRORTYPES.NO_TYPE_SET ? null : emailErrorType;
      return {
        ...state,
        email: action.email,
        emailError
      };
    }
    case ACTIONS.SET_TITLE_HONEYPOT: {
      return {
        ...state,
        titleHoneypot: action.titleHoneypot
      };
    }
    case ACTIONS.SET_INDUSTRY_HONEYPOT: {
      return {
        ...state,
        industryHoneypot: action.industryHoneypot
      };
    }
    case ACTIONS.USER_STATUS_FETCHED: {
      return {
        ...state,
        isReseller: action.status.is_reseller_customer
      };
    }
    case ACTIONS.TOGGLE_IS_TRANSACTIONAL: {
      return {
        ...state,
        isTransactional: 1 - state.isTransactional
      };
    }
    case ACTIONS.TOGGLE_IS_MARKETING: {
      return {
        ...state,
        isMarketing: 1 - state.isMarketing
      };
    }
    case ACTIONS.CHECK_IS_FORM_VALID: {
      return {
        ...state,
        isValidForm
      };
    }
    case ACTIONS.SET_ADS_USER: {
      return {
        ...state,
        isAds: action.isAdsUser
      };
    }
    case ACTIONS.SET_COUNTRY_CODE: {
      return {
        ...state,
        countryCode: action.countryCode
      };
    }
    default:
      return state;
  }
};

export default profileInputs;
