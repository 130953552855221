import { connect } from 'react-redux';
import { addAddons, removeAddon } from '../actions/addOns.js';

const mapDispatchToProps = dispatch => ({
  addAddons: (addon) => {
    dispatch(addAddons(addon));
  },
  removeAddon: (addon) => {
    dispatch(removeAddon(addon));
  },
});

function mapStateToProps({ addOns }) {
  return { addOns };
}

export default ComposedCmp => (connect(mapStateToProps, mapDispatchToProps)(ComposedCmp));
