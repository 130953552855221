import React from 'react';
import { Loader } from '@sendgrid/ui-components/loader';
import Styles from './fullPageLoader.module.scss';

const FullPageLoader = () => (
  <div className={Styles['full-page-load']}>
    <Loader large />
  </div>
);

export default FullPageLoader;
