import React from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { COOKIES } from '../constants';
import ConnectedPaymentForm from '../components/PaymentForm';
import Layout from '../components/Layout';
import withPackages from '../containers/withPackages';
import accountTypes from '../constants/accountTypes';
import { isPaymentRequired } from '../utils/displayHelpers';
import { getCookie } from '../utils/cookieHelpers';

const adsSubtitle = ['Your card ', <strong>will not be charged</strong>, ' until you publish an ad campaign.'];
const emailSubtitle = 'All transactions are secure and encrypted.';

class Billing extends React.Component {
  componentDidMount() {
    if (!getCookie(COOKIES.SIGNUP_SESSION)) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate() {
    // If the user ends up here and we don't have any paid packages
    // or we're signing up a reseller customer (AWS)
    // we shouldn't be asking for payment information
    const { packages } = this.props;
    const { activePackage } = packages;
    if (!isPaymentRequired(activePackage)) {
      this.props.history.push('/account_details');
    }
  }

  getSubtitle = () => {
    if (this.props.location.pathname.indexOf(accountTypes.ads.toLowerCase()) > -1) {
      return adsSubtitle;
    }
    return emailSubtitle;
  };

  render() {
    this.getSubtitle();
    return (
      <Layout
        title="Add Your Billing Information"
        subtitle={this.getSubtitle()}
      >
        <ConnectedPaymentForm />
      </Layout>
    );
  }
}

Billing.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  packages: PropTypes.shape({
    activeIsFree: PropTypes.bool.isRequired,
    activePackage: PropTypes.array,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withPackages(Billing));
