import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from '@sendgrid/bloodhound';
import Styles from './totalcard.module.scss';
import { calculateEstimatedProratedPrice, calculateTotalPrice, dollarize } from '../../utils/displayHelpers';
import { YourProductsEvents } from '../../analytics/yourProductsEvents';

const overageHref = 'https://docs.sendgrid.com/ui/account-and-settings/billing/#limits-and-overage-rates';

const onOverageClick = () => {
  analytics.track(YourProductsEvents.overageRatesClicked());
};

// eslint-disable-next-line react/prefer-stateless-function
export class TotalCard extends React.Component {
  render() {
    const { activePackage, addOns } = this.props;
    const totalPrice = calculateTotalPrice(activePackage, addOns);
    const proratedPrice = calculateEstimatedProratedPrice(totalPrice);
    return (
      <React.Fragment>
        <div className={Styles.totalCard}>
          <div className={Styles.titleRow}>
            <div className={Styles.title}>Total Price</div>
            <div className={Styles.price}>
              {dollarize(totalPrice)}
              <span>
/month
                {totalPrice === 0 ? '' : '*'}
              </span>
            </div>
          </div>
          <div className={Styles.description}>
            {totalPrice > 0
              && (
              <p>
                You’ll pay this amount plus
                {' '}
                <a
                  target="_blank"
                  href={overageHref}
                  rel="noopener noreferrer"
                  onClick={onOverageClick}
                >
                  any overages
                </a>
                {' '}
on the first day of the month.
                {' '}
You’ll pay a prorated price of
                <span className={Styles.proratedPrice}>
                  {` ${dollarize(proratedPrice)}* `}
                </span>
                today.
              </p>
              )
            }
            {totalPrice === 0
              && (
              <p>
                Sign up for free&mdash;no credit card required.
              </p>
              )
            }
          </div>
        </div>
        {totalPrice !== 0
          && (
          <div className={Styles.taxDisclaimer}>
              *Taxes may apply
          </div>
          )
        }
      </React.Fragment>
    );
  }
}

TotalCard.propTypes = {
  addOns: PropTypes.shape({
    ease: PropTypes.number,
    ip: PropTypes.number,
  }).isRequired,
  activePackage: PropTypes.arrayOf(PropTypes.shape({
    adjusted_price: PropTypes.number,
    base_price: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default TotalCard;
