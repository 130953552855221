export default {
  MAKO_AUTH: 'mako_auth_token',
  CJ_DETAILS: 'cj_details',
  SF_DETAILS: 'sf_details',
  SIGNUP_SESSION: 'signup_session_token',
  SENDGRID_PARTNER: 'sendgrid_partner',
  SELECTED_PACKAGES: 'tneSignUpVals',
  SETUP_2FA_REQUIRED: 'setup_2fa_required',
  SETUP_2FA_REQUIRED_CHECKPOINT: 'setup_2fa_required_checkpoint',
  UNIFIED_USER_INFO: 'unified_user_info',
};
