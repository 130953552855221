import { analytics } from '@sendgrid/bloodhound';
import getEnvVariable from '../environment';
import { ENVIRONMENT } from '../../constants';

type AnalyticsOptions = Parameters<typeof analytics.init>[0];

type BeforeSend = AnalyticsOptions['datadogRum']['beforeSend'];

const redactUsernameRegex = /(?<=\/username\/)[^\/]*/;
const REDACTED_PATH_PARAM = ':REDACTED';

const redactPathParams = (urlString: string) => {
    const url = new URL(urlString);
  
    url.pathname = url.pathname
      .replace(redactUsernameRegex, REDACTED_PATH_PARAM)
  
    return url.toString();
  };

export const beforeSend: BeforeSend = (event) => {
  if (event.type === 'resource') {
    const { host } = new URL(event.resource.url);
    const shouldRedactPathParams =
      ["xhr", "fetch"].includes(event.resource.type) &&
      host === getEnvVariable(ENVIRONMENT.MAKO_URL);

      if (shouldRedactPathParams) {
        event.resource.url = redactPathParams(event.resource.url);
      }   
  }
};
