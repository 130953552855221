import React, { useEffect } from 'react';
import { TextInput } from '@sendgrid/ui-components/text-input';
import { Select } from '@sendgrid/ui-components/select';
import PropTypes from 'prop-types';
import withProfileInputs from '../containers/withProfileInputs';
import { alphabetizedCountryCodes, topCountryCodeOptions } from '../constants/countryCodes';
import Styles from './profileForm.module.scss';
import { trackOnce } from '../utils/Analytics';
import { AccountDetailsEvents } from '../analytics/accountDetailsEvents';
import { WriteSelectors } from './personalInfo-qa-hooks';

const trackCountryCodeCompleted = trackOnce();

export const PersonalInfo = (props) => {
  const defaultCountryCode = topCountryCodeOptions[0];
  const isUnifiedUser = Boolean(props.unifiedUser);

  useEffect(() => {
    props.setCountryCode(defaultCountryCode);
  }, []);

  const handleCountryCodeChange = (countryCode) => {
    trackCountryCodeCompleted(AccountDetailsEvents.countryCodeCompleted());
    props.setCountryCode(countryCode);
  };

  const firstNameClassStyle = `${isUnifiedUser ? `${Styles.readOnly} ` : ''}${Styles.firstNameId}`
  const lastNameClassStyle = `${isUnifiedUser ? `${Styles.readOnly} ` : ''}${Styles.lastNameId}`

  return (
    <section data-role="personal-info-container">
      <div className={Styles.row}>
        <div className={Styles.col}>
          <TextInput
            type="text"
            label="First Name"
            id="first-name-id"
            className={firstNameClassStyle}
            isRequired
            readOnly={isUnifiedUser}
            disabled={isUnifiedUser}
            onChange={props.setFirstName}
            onBlur={props.blurFirstName}
            isValid={props.profileInputs.firstNameError === null}
            value={props.profileInputs.firstName}
            info={props.profileInputs.firstNameError}
            data-role="first-name-id"
            {...WriteSelectors.firstNameInput}
          />
        </div>
        <div className={Styles.col}>
          <TextInput
            type="text"
            label="Last Name"
            id="last-name-id"
            className={lastNameClassStyle}
            isRequired
            readOnly={isUnifiedUser}
            disabled={isUnifiedUser}
            onChange={props.setLastName}
            onBlur={props.blurLastName}
            isValid={props.profileInputs.lastNameError === null}
            value={props.profileInputs.lastName}
            info={props.profileInputs.lastNameError}
            data-role="last-name-id"
            {...WriteSelectors.lastNameInput}
          />
        </div>
      </div>
      {isUnifiedUser &&
      <div className={Styles.row}>
        <div className={Styles.col}>
          <TextInput
            type="text"
            label="Email"
            id="unified-email-id"
            className={`${Styles.readOnly} ${Styles.emailId}`}
            isRequired
            readOnly={isUnifiedUser}
            disabled={isUnifiedUser}
            value={props.unifiedUser.email}
            data-role="unified-email-id"
          />
        </div>
      </div>
      }
      <div className={Styles.row}>
        <div className={Styles.col}>
          <TextInput
            type="text"
            label="Company Name"
            id="company-name-id"
            className={Styles.companyNameId}
            isRequired
            onChange={props.setCompanyName}
            onBlur={props.blurCompanyName}
            isValid={props.profileInputs.companyNameError === null}
            value={props.profileInputs.companyName}
            info={props.profileInputs.companyNameError}
            data-role="company-name-id"
            {...WriteSelectors.companyNameInput}
          />
        </div>
        <div className={Styles.col}>
          <TextInput
            type="text"
            label="Company Website"
            id="website-id"
            isRequired
            onChange={props.setWebsite}
            onBlur={props.blurWebsite}
            isValid={props.profileInputs.websiteError === null}
            value={props.profileInputs.website}
            info={props.profileInputs.websiteError}
            data-role="website-id"
            {...WriteSelectors.websiteInput}
          />
        </div>
      </div>
      { props.profileInputs.isReseller
        ? (
          <div className={Styles.row}>
            <div className={Styles.col}>
              <TextInput
                type="text"
                label="Contact Email"
                id="email-id"
                isRequired
                onChange={props.setEmail}
                onBlur={props.blurEmail}
                isValid={props.profileInputs.emailError === null}
                value={props.profileInputs.email}
                info={props.profileInputs.emailError}
                data-role="email-id"
                {...WriteSelectors.companyEmailInput}
              />
            </div>
          </div>
        )
        : null
      }
      <div className={Styles.row}>
        <div className={Styles.col}>
          <Select
            options={[
              ...topCountryCodeOptions,
              { label: '—', options: alphabetizedCountryCodes },
            ]}
            label="Country Code"
            defaultValue={defaultCountryCode}
            onChange={handleCountryCodeChange}
            {...WriteSelectors.countryCodeSelect}
          />
        </div>
        <div className={Styles.col}>
          <TextInput
            type="text"
            label="Phone Number"
            id="phone-number-id"
            onChange={props.setPhoneNumber}
            onBlur={props.blurPhoneNumber}
            isRequired
            value={props.profileInputs.phoneNumber}
            isValid={props.profileInputs.phoneNumberError === null}
            info={props.profileInputs.phoneNumberError}
            data-role="phone-number-id"
            {...WriteSelectors.phoneInput}
          />
        </div>
      </div>
    </section>
  );
};

PersonalInfo.propTypes = {
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  setWebsite: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setCountryCode: PropTypes.func.isRequired,
  blurFirstName: PropTypes.func.isRequired,
  blurLastName: PropTypes.func.isRequired,
  blurCompanyName: PropTypes.func.isRequired,
  blurPhoneNumber: PropTypes.func.isRequired,
  blurWebsite: PropTypes.func.isRequired,
  blurEmail: PropTypes.func.isRequired,
  profileInputs: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    firstNameError: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    lastNameError: PropTypes.string,
    companyName: PropTypes.string.isRequired,
    companyNameError: PropTypes.string,
    phoneNumber: PropTypes.string.isRequired,
    phoneNumberError: PropTypes.string,
    website: PropTypes.string.isRequired,
    websiteError: PropTypes.string,
    email: PropTypes.string,
    emailError: PropTypes.string,
    isReseller: PropTypes.bool.isRequired,
  }).isRequired,
  unifiedUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })
};


export default withProfileInputs(PersonalInfo);
