import { useCallback, useState, useMemo } from 'react';
import getEnvVariable from '../utils/environment';
import { COOKIES, ENVIRONMENT } from '../constants';
import { setCookie } from './cookieHelpers';

const API_URL = getEnvVariable(ENVIRONMENT.MAKO_URL);
export const EMAIL_ALREADY_EXISTS_ERROR = 'This email is already signed up.';

export const postUnifiedSignup = async (
  access_token: string,
  id_token: string
): Promise<Response> => {
  const signupApi = `${API_URL}/v3/unified_signup`;
  return fetch(signupApi, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`
    },
    body: JSON.stringify({
      id_token
    })
  });
};

export const postSessions = async (access_token: string): Promise<Response> => {
  return fetch(`${API_URL}/v3/sessions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`
    }
  });
};

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong, please try again later.';

export const extractErrorMessage = async (
  response: Response | undefined
): Promise<string | undefined> => {
  if (!response || response.status >= 500) {
    return DEFAULT_ERROR_MESSAGE;
  } else if (response.status === 409) {
    return EMAIL_ALREADY_EXISTS_ERROR;
  }

  const errorResponse = await response.json();

  if (errorResponse?.errors?.length > 0) {
    return errorResponse.errors[0].message;
  }

  return DEFAULT_ERROR_MESSAGE;
};

export const useCreateAccount = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const createAccount = useCallback((access_token, id_token) => {
    setIsError(false);
    const handleCreateAccount = async () => {
      let signupResponse: Response | undefined;
      try {
        setIsLoading(true);
        signupResponse = await postUnifiedSignup(access_token, id_token);
      } catch (error) {
        console.error(error);
      }

      if (!signupResponse || !signupResponse.ok) {
        setErrorMsg(await extractErrorMessage(signupResponse));
        setIsError(true);
        setIsLoading(false);
        return false;
      }

      let sessionsResponse: Response | undefined;
      try {
        sessionsResponse = await postSessions(access_token);
      } catch (error) {
        console.error(error);
      }

      if (!sessionsResponse || !sessionsResponse.ok) {
        setErrorMsg(await extractErrorMessage(sessionsResponse));
        setIsError(true);
        setIsLoading(false);
        return false;
      }

      const { token: makoToken } = await sessionsResponse.json();
      setCookie(COOKIES.MAKO_AUTH, makoToken, {
        secure: true
      });

      setIsLoading(false);
      return true;
    };
    return handleCreateAccount();
  }, []);

  return useMemo(
    () => ({
      errorMsg,
      isError,
      isLoading,
      createAccount
    }),
    [isError, isLoading, createAccount, errorMsg]
  );
};
