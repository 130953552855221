import jsCookie from 'js-cookie';

jsCookie.defaults.path = '/';
if (window.location.hostname !== 'localhost') {
  jsCookie.defaults.domain = '.sendgrid.com';
}

export const setCookie = (cookieName, value, options = {}) => jsCookie.set(cookieName, value, options);

export const removeCookie = cookieName => jsCookie.remove(cookieName);

export const getParsedCookie = cookieName => jsCookie.getJSON(cookieName);

export const getCookie = cookieName => jsCookie.get(cookieName);
