function getOfferingById(offerings, offeringId) {
  return offerings.find(offering => offering.name.toLowerCase() === offeringId);
}

function getProductPricingByName(offering, productName) {
  return offering
    && offering.pricing
    && offering.pricing.products
    && offering.pricing.products[productName];
}

export function mapEmailApiPlan(offering) {
  const basePricing = getProductPricingByName(offering, 'ei_base');
  const basePriceString = basePricing && basePricing.amount;
  const basePrice = basePriceString ? Number.parseFloat(basePriceString) : 0;

  const id = offering && offering.name;
  const name = offering && offering.display && offering.display.en_us;

  return {
    id,
    name,
    base_price: basePrice,
    adjusted_price: basePrice,
    plan_type: 'Email API',
  };
}

export function mapMarketingCampaignsPlan(offering) {
  const basePricing = getProductPricingByName(offering, 'mc_base');
  const basePriceString = basePricing && basePricing.amount;
  const basePrice = basePriceString ? Number.parseFloat(basePriceString) : 0;

  const name = offering && offering.display && offering.display.en_us;
  const id = offering && offering.name;

  return {
    id,
    name,
    base_price: basePrice,
    adjusted_price: basePrice,
    plan_type: 'Marketing Campaigns',
  };
}

export function mapEaseAddon(signUpVals, emailApiOffering, oesOfferings) {
  const noEaseAddon = { easePrice: 0, ease: 3 };

  if (!signUpVals || !emailApiOffering) {
    return noEaseAddon;
  }

  const { addons } = signUpVals;

  const hasEaseAddon = addons && addons.ease === 30;
  if (!hasEaseAddon) { return noEaseAddon; }

  const planShortName = emailApiOffering.name.split('.')[2];
  const easeOffering = getOfferingById(oesOfferings, `sg.x.ease-30-${planShortName}.v1`);
  const easePricing = getProductPricingByName(easeOffering, 'ease_base');
  if (!easeOffering || !easePricing) {
    return noEaseAddon;
  }

  const easePrice = Number.parseFloat(easePricing.amount);
  return {
    ease: addons.ease,
    easePrice,
  };
}

export function mapIpAddon(signUpVals, oesOfferings) {
  const noIpAddon = { ipPrice: 0, ip: 0 };

  if (!signUpVals) {
    return noIpAddon;
  }

  const { addons } = signUpVals;

  const hasIpAddon = addons && addons.ip > 0;
  if (!hasIpAddon) { return noIpAddon; }

  const ipOffering = getOfferingById(oesOfferings, 'sg.x.ip.v2');
  const ipPricing = getProductPricingByName(ipOffering, 'ip_base');
  if (!ipOffering || !ipPricing) {
    return noIpAddon;
  }

  const ipPrice = Number.parseFloat(ipPricing.amount);
  // We only allow one additional IP as an addon during
  // sign up. This override handles a scenario where the
  // sign up cookie is modified to include more than 1 IP
  return {
    ip: 1,
    ipPrice,
  };
}
