import { selectSelectorGenerator, writeSelectorGenerator } from '../qa-hooks/typedSelectorHelpers';

const QaHooks = {};

const hooks = { 
    getStartedButton: 'getStartedButton',
};

const Selectors = { ...selectSelectorGenerator(hooks), ...QaHooks };
const WriteSelectors = writeSelectorGenerator(hooks);

export { Selectors, WriteSelectors };