import { combineReducers } from 'redux';
import paymentForm from './paymentForm';
import formInputs from './formInputs';
import profileInputs from './profileInputs';
import packages from './packages';
import appIndicators from './appIndicators';
import addOns from './addOns';
import emailConfirmationInputs from './emailConfirmationInputs';

const reducers = combineReducers({
  formInputs, profileInputs, paymentForm, packages, appIndicators, addOns, emailConfirmationInputs,
});

export default reducers;
