import React from 'react';
import PropTypes from 'prop-types';
import Styles from './MultiProductCard.module.scss';
import { dollarize } from '../../utils/displayHelpers';

export const MultiProductCard = ({ product }) => (
  <section className={Styles.multiProductCard}>
    <div className={Styles.titleRow}>
      <div className={Styles.title}>{product.plan_type}</div>
    </div>
    <div className={Styles.descriptionRow}>
      <div className={Styles.description}>
        {product.name}
      </div>
      <div className={Styles.priceWrapper}>
        {(product.adjusted_price !== product.base_price)
          && <div className={Styles.strikePrice}>{dollarize(product.base_price)}</div>
        }
        <div className={Styles.price}>
          {dollarize(product.adjusted_price)}
          <span className={Styles.timeUnit}>/month</span>
        </div>
      </div>
    </div>
  </section>
);


MultiProductCard.propTypes = {
  product: PropTypes.shape({
    adjusted_price: PropTypes.number.isRequired,
    base_price: PropTypes.number.isRequired,
    plan_type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default MultiProductCard;
