import { eHawkTalon } from '../vendor/eHawkTalon';

let _base64Fingerprint: string;
let _rawFingerprint: string;

export const getBrowserFingerprintHeader = () => _base64Fingerprint;

export const getRawBrowserFingerprint = () => _rawFingerprint;

const createTalonInputElement = () => {
  const talonInputElement = document.createElement('input');
  talonInputElement.setAttribute('type', "hidden");
  talonInputElement.setAttribute('name', 'talon');
  talonInputElement.setAttribute('value', '{"version": 6, "status": -1}')
  talonInputElement.setAttribute('id', 'talon');
  if(!document.body){
    throw new Error('failed to create talon input element');
  }
  document.body.append(talonInputElement);
  return talonInputElement;
}

const isFingerprintValid = (fingerprint: string) => {
  try {
    const fingerprintObj = JSON.parse(fingerprint);
    return fingerprintObj.status === 0;
  } catch (error) {
    return false;
  }
};

// https://ehawk.net/support/api.php#talon
// The eHawk script sets the value attribute of a hidden input element
// that represents the browser fingerprint. The browser needs a few ticks
// to write the talon value to the DOM, which is why this is async
export const initBrowserFingerprint = () => new Promise((resolve, reject) => {
  try {
    const talonInput = (document.getElementById('talon') || createTalonInputElement()) as HTMLInputElement;
    // If the talon isn't generated for some reason, we need to bail out at some point
    const timeoutHandle = window.setTimeout(() => reject('timed out generating talon'), 3000);
    // Watch for the value to be written to the input element.
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'value'
          ) {
            if (!isFingerprintValid(talonInput.value)) {
              return
            }
            clearTimeout(timeoutHandle);
            observer.disconnect();
            _base64Fingerprint = btoa(talonInput.value);
            _rawFingerprint = talonInput.value;
            return resolve(talonInput.value);
          }
      });
    });

    observer.observe(talonInput, {
      attributes: true,
      childList: false,
      subtree: false,
    });

    // Generate the browser fingerprint (talon)
    eHawkTalon();
  }
  catch (error) {
    return reject('failed to generate browser fingerprint');
  }
});
