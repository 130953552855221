import { analytics } from '@sendgrid/bloodhound';
import {
  getEmailApiPlan, getMarketingCampaignsPlan, getAdsPlan, getAwsPlan,
} from './packageHelper';
import packageConfig from './packageConfig.js';
import getEnvVariable from './environment';
import { ENVIRONMENT } from '../constants';
import { getDatadogRumOptions } from './datadogRum/datadogRum';


const { emailApiDefaultPackage, marketingCampaignsDefaultPackage } = packageConfig;

/* eslint-disable */

/**
 * Adds optimizely to the page
 */
function LoadOptimizely() {
  const optScript = document.createElement('script');
  optScript.type = 'text/javascript';
  const first = document.getElementsByTagName('script')[0];
  first.parentNode.insertBefore(optScript, first);
}

let analyticsLoaded = false;
const LoadAnalytics = (apiKey) => {
  // only load analytics once
  if (analyticsLoaded) {
    return false;
  }

  const options = {
    segmentApiKey: apiKey,
    apiBaseUrl: getEnvVariable(ENVIRONMENT.MAKO_URL),
    datadogRum: getDatadogRumOptions(),
  };

  analytics.init(options);

  analyticsLoaded = true;
  return analyticsLoaded;
};

/**
 * Converts the package array into an object that
 * contains the plan type and id as a key value pair.
 * This is the format expected for analytics events.
 * @param  {object[]} activePackage
 * @param  {string} eiPlanKey
 * @param  {string} mcPlanKey
 */
export const getPackageEventData = (activePackage, eiPlanKey, mcPlanKey) => {
  const packageWithDefaults = defaultIfEmpty(activePackage);
  const eiPlan = getEmailApiPlan(packageWithDefaults)
    || getAwsPlan(packageWithDefaults)
    || getAdsPlan(packageWithDefaults);

  const mcPlan = getMarketingCampaignsPlan(packageWithDefaults);
  return {
    [eiPlanKey]: eiPlan ? eiPlan.id : '',
    [mcPlanKey]: mcPlan ? mcPlan.id : '',
  };
}

/**
 * Adds user info to google tag manager
 * @param  {int} userId
 * @param  {string} email
 * @param  {object[]} activePkg
 */
export const pushGTM = (userId, email, activePkg) => {
  const products = defaultIfEmpty(activePkg);

  const strUserId = userId.toString();

  const optimizelyNamesMap = window['optimizely'] && window['optimizely'].get && window['optimizely'].get('state') ?
  window['optimizely'].get('state').getVariationMap() :
  {};

  let transactionTotal = products
    .map(pkg => parseFloat(pkg.base_price))
    .reduce((total, price) => total + price, 0);

  if (isNaN(transactionTotal)) {
    transactionTotal = 0.0;
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      'event': 'userSignup',
      'transactionAffiliation': 'variationNamesMap',
      'sgid': strUserId || '',
      'uid': email,
      'transactionId': strUserId,
      'transactionProducts': products.map(pkg => ({
        'sku': pkg.id,
        'name': pkg.name,
        'category': JSON.stringify(optimizelyNamesMap),
        'price': pkg.base_price,
        'quantity': 1,
      })),
      'transactionTotal': transactionTotal,
    });

    const packagesData = getPackageEventData(activePkg, 'ei', 'mc');
    window.dataLayer.push({
      'event': 'package',
      'packages': packagesData,
    });
  }
};

export function trackOnce() {
  let hasTracked = false;
  return function() {
    if (hasTracked) {
      return;
    }
    hasTracked = true;
    analytics.track.apply(this, arguments);
  };
}

function defaultIfEmpty(activePackage) {
  if (activePackage && activePackage.length) {
    return activePackage;
  }
  return [
    emailApiDefaultPackage,
    marketingCampaignsDefaultPackage,
  ];
}

export default LoadAnalytics;
