import packageConfig from './packageConfig';

export function getAwsPlan(activePackage) {
  return activePackage && activePackage.find(pkg => pkg.is_aws);
}

export function getAdsPlan(activePackage) {
  return activePackage && activePackage.find(pkg => pkg.id in packageConfig.adsPackages);
}

export function getEmailApiPlan(activePackage) {
  return activePackage && activePackage.find(pkg => pkg.id.includes('sg.ei'));
}

export function getMarketingCampaignsPlan(activePackage) {
  return activePackage && activePackage.find(pkg => pkg.id.includes('sg.mc'));
}
