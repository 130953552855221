import { connect } from 'react-redux';
import { dismissAlert } from '../actions';

const mapDispatchToProps = dispatch => ({
  dismissAlert: () => {
    dispatch(dismissAlert());
  },
});

function mapStateToProps({ appIndicators, packages }) {
  return { appIndicators, packages };
}

export default ComposedCmp => (connect(mapStateToProps, mapDispatchToProps)(ComposedCmp));
