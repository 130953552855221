import React from 'react';
import PropTypes from 'prop-types';
import Styles from './addoncard.module.scss';
import { dollarize } from '../../utils/displayHelpers';

export class AddOnCard extends React.Component {
  addOnObj = [];

  componentDidMount = () => {
    this.createAddOnObj(this.props.addOns);
  }


  createAddOnObj = (addOns) => {
    if (addOns.ip > 0 && addOns.ipPrice > 0) {
      this.addOnObj.push({ title: `${addOns.ip} additional dedicated IP address`, price: addOns.ip * addOns.ipPrice });
    }

    if (addOns.ease > 3 && addOns.easePrice > 0) {
      this.addOnObj.push({ title: `${addOns.ease} days of Email Activity history`, price: addOns.easePrice });
    }
  };

  buildAddOn = (title, price) => (
    <div className={Styles.itemRow}>
      <div className={Styles.itemTitle}>
        {title}
      </div>
      <div className={Styles.itemPrice}>
        <span>
          {dollarize(price)}
        </span>
        /month
      </div>
    </div>
  );

  render() {
    return (
      <section className={Styles.addOnCard}>
        <div className={Styles.titleRow}>
          <div className={Styles.title}>Add Ons</div>
        </div>

        {this.addOnObj.map(addOn => (
          <div className={Styles.addOnRow} key={addOn.title}>
            {this.buildAddOn(addOn.title, addOn.price)}
          </div>
        ))}
      </section>
    );
  }
}
AddOnCard.propTypes = {
  addOns: PropTypes.shape({
    ease: PropTypes.number,
    ip: PropTypes.number,
  }).isRequired,
};

export default AddOnCard;
