export default {
  GET_PAYMENT_FORM: 'getPaymentForm',
  ZUORA_PAYMENT_FAILED: 'zuoraPaymentFailed',
  CREATE_FREE_USER: 'createFreeUser',
  CREATE_USER_SUCCESS: 'createUserSuccess',
  CREATE_USER_ERROR: 'createUserError',
  CREATE_USER_ERROR_PASSWORD: 'createUserErrorPassword',
  CREATE_USER_FAILURE: 'createUserFailure',
  GET_PACKAGES: 'getPackages',
  GET_PACKAGES_SUCCESS: 'getPackagesSuccess',
  GET_PACKAGES_FAILURE: 'getPackagesFailure',
  // TODO
  // CREATE_BILLING_ACCOUNT : 'createBillingAccount',
  // CREATE_BILLING_ACCOUNT_SUCCESS : 'createBillingAccountSuccess',
  // CREATE_BILLING_ACCOUNT_ERROR : 'createBillingAccountError',
  // SEND_PAYMENT_CONFIRMATION : 'sendPaymentConfirmation',
  // SEND_PAYMENT_CONFIRMATION_SUCCESS : 'sendPaymentConfirmationSuccess',
  // SEND_PAYMENT_CONFIRMATION_ERROR : 'sendPaymentConfirmationError',
  SET_USERNAME: 'setUsername',
  SET_PASSWORD: 'setPassword',
  SET_PASSWORD_ERROR: 'setPasswordError',
  SET_CONFIRM_PASSWORD: 'setConfirmPassword',
  SET_EMAIL: 'setEmail',
  USERNAME_EXISTS_CHECK: 'usernameExistsCheck',
  SET_PACKAGE: 'setPackage',
  POST_RECAPTCHA: 'postRecaptcha',
  SET_RECAPTCHA_TOKEN: 'setRecaptchaToken',
  SET_FIRST_NAME: 'setFirstName',
  SET_LAST_NAME: 'setLastName',
  SET_PHONE_NUMBER: 'setPhoneNumber',
  SET_COMPANY_NAME: 'setCompanyName',
  SET_WEBSITE: 'setWebsite',
  SET_TITLE_HONEYPOT: 'setTitleHoneypot',
  SET_INDUSTRY_HONEYPOT: 'setIndustryHoneypot',
  TOGGLE_IS_TRANSACTIONAL: 'toggleIsTransactional',
  TOGGLE_IS_MARKETING: 'toggleIsMarketing',
  CHECK_IS_FORM_VALID: 'checkIsFormValid',
  ON_SAVE: 'onSave',
  USER_BANNED: 'userBanned',
  USER_PROFILE_FAILED: 'userProfileFailed',
  USER_PROFILE_SUCCESS: 'userProfileSuccess',
  USER_STATUS_FETCHED: 'userStatusFetched',
  REDIRECT_PAGE: 'redirectPage',
  ADD_ADDON: 'addAddOn',
  REMOVE_ADDON: 'removeAddOn',
  SET_ADS_USER: 'setAdsUser',
  TOGGLE_TOS: 'toggleTos',
  SET_CONFIRMATION_EMAIL: 'setConfirmationEmail',
  ON_SEND: 'onSend',
  SET_COUNTRY_CODE: 'setCountryCode'
};
