import { ENVIRONMENT } from '../../constants';
import getEnvVariable from '../environment';
import { beforeSend } from './beforeSend';



export const getDatadogRumOptions = () => {
  const datadogAnalyticsEnabled = getEnvVariable(ENVIRONMENT.DATADOG_ANALYTICS_ENABLED) === 'true' && process.env.SIGNUP_ENV === 'production';

  if (!datadogAnalyticsEnabled) {
    return undefined;
  }

  const applicationId = getEnvVariable(ENVIRONMENT.DATADOG_APPLICATION_ID);
  const clientToken = getEnvVariable(ENVIRONMENT.DATADOG_CLIENT_TOKEN);

  if (!applicationId) {
    console.error(
      'Datadog RUM is not initialized, DATADOG_APPLICATION_ID is not set'
    );
    return undefined;
  }

  if (!clientToken) {
    console.error(
      'Datadog RUM is not initialized, DATADOG_CLIENT_TOKEN is not set'
    );
    return undefined;
  }

  const env = process.env.SIGNUP_ENV;

  return {
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'email-signup',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    beforeSend
  };
};
